import React, { useState } from 'react'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import FileUpload from '../../../services/general/fileUpload';
import { toast } from 'react-toastify';
import plugins from "suneditor/src/plugins";
import CodeMirror from "codemirror";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/lib/codemirror.css";

const TextSunEditor = ({
    onChange,
    value
}) => {

    function handleImageUploadBefore(files, info, uploadHandler) {
        const currentFile = files[0];
    
        FileUpload.upload(currentFile)
            .then(res => {
                if (res.statusCode === 200) {
                    const url = res.content?.value;
                    uploadHandler({
                        result: [{ url }],
                    });
                    toast.success(res.message);
                } else {
                    toast.error(res.message);
                }
            })
            .catch(error => {
                console.error("Upload failed:", error);
                toast.error("Failed to upload the image.");
            });
    }

    const cleanContent = content => {
        return content.replace(/<p><br><\/p>/g, '');
    };
    
    const handleEditorChange = (content) => {
        const cleanedContent = cleanContent(content);
        onChange(cleanedContent);
    };

  return (
    <SunEditor 
        setOptions={{
            height: 400,
            plugins: plugins,
            buttonList: [
                    ['undo', 'redo'],
                    ['font', 'fontSize', 'formatBlock'],
                    ['paragraphStyle', 'blockquote'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                    ['fontColor', 'hiliteColor', 'textStyle'],
                    ['removeFormat'],
                    '/',
                    ['outdent', 'indent'],
                    ['align', 'horizontalRule', 'list', 'lineHeight'],
                    ['table', 'link', 'image', 'video', 'audio' /** ,'math' */],
                    ['fullScreen', 'showBlocks', 'codeView'],
                    ['preview', 'print'],
                    ['save', 'template'],
                    // responsive
                    ['%1161', [
                        ['undo', 'redo'],
                        [':p-Formats-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                        ['fontColor', 'hiliteColor', 'textStyle'],
                        ['removeFormat'],
                        ['outdent', 'indent'],
                        ['align', 'horizontalRule', 'list', 'lineHeight'],
                        ['-right', 'save'],
                        ['-right', ':i-Etc-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView', 'preview'],
                        ['-right', ':r-Table&Media-default.more_plus', 'table', 'link', 'image', 'video'],
                    ]],
                    ['%893', [
                        ['undo', 'redo'],
                        [':p-Formats-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                        ['bold', 'underline', 'italic', 'strike'],
                        [':t-Fonts-default.more_text', 'subscript', 'superscript', 'fontColor', 'hiliteColor', 'textStyle'],
                        ['removeFormat'],
                        ['outdent', 'indent'],
                        ['align', 'horizontalRule', 'list', 'lineHeight'],
                        ['-right', 'save'],
                        ['-right', ':i-Etc-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView', 'preview'],
                        ['-right', ':r-Table&Media-default.more_plus', 'table', 'link', 'image', 'video'],
                    ]],
                    ['%855', [
                        ['undo', 'redo'],
                        [':p-Formats-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                        [':t-Fonts-default.more_text', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'fontColor', 'hiliteColor', 'textStyle'],
                        ['removeFormat'],
                        ['outdent', 'indent'],
                        ['align', 'horizontalRule', 'list', 'lineHeight'],
                        [':r-Table&Media-default.more_plus', 'table', 'link', 'image', 'video'],
                        ['-right', 'save'],
                        ['-right', ':i-Etc-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView', 'preview'],
                    ]],
                    ['%563', [
                        ['undo', 'redo'],
                        [':p-Formats-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                        [':t-Fonts-default.more_text', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'fontColor', 'hiliteColor', 'textStyle'],
                        ['removeFormat'],
                        ['outdent', 'indent'],
                        [':e-List&Line-default.more_horizontal', 'align', 'horizontalRule', 'list', 'lineHeight'],
                        [':r-Table&Media-default.more_plus', 'table', 'link', 'image', 'video'],
                        ['-right', 'save'],
                        ['-right', ':i-Etc-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView', 'preview'],
                    ]],
                    ['%458', [
                        ['undo', 'redo'],
                        [':p-Formats-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                        [':t-Fonts-default.more_text', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'fontColor', 'hiliteColor', 'textStyle', 'removeFormat'],
                        [':e-List&Line-default.more_horizontal', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'lineHeight'],
                        [':r-Table&Media-default.more_plus', 'table', 'link', 'image', 'video'],
                        ['-right', 'save'],
                        ['-right', ':i-Etc-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView', 'preview'],
                    ]]
            ],
            codeMirror: CodeMirror,
            addTagsWhitelist: '*',
            pasteTagsWhitelist: '*',
            attributesWhitelist: '*',
            defaultTagAttributes: { class: true, style: true },
        }}
        autoFocus={false}
        onImageUploadBefore={handleImageUploadBefore}
        onChange={handleEditorChange}
        setContents={value}
    />
  )
}

export default TextSunEditor