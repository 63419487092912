import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useFieldArray, useForm } from 'react-hook-form';
import { Context } from '../../..';

const TraitEditModal = observer(({
    show,
    setShow,
    traits, 
    setTraits, 
    traitIndex
}) => {
    const handleClose = () => setShow(false);

    const {app} = useContext(Context);

    const {register, control, setValue, getValues, handleSubmit, formState: {errors}} = useForm();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'elements'
    });

    useEffect(() => {
        if(traits && traits[traitIndex]){
            setValue('title', traits[traitIndex].title);
            setValue('value', traits[traitIndex].value);
            setValue('elements', traits[traitIndex].elements);
        }
    }, [traits])

    const customSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const data = getValues();

        traits[traitIndex] = data;

        const tempArr = traits;
        
        setTraits(tempArr);
        setShow(false);
    }

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
            <h2>{app.localizationsItems?.edit_attribut}</h2>
        </Modal.Header>
        <Modal.Body>
            <form>
                <div className="form-floating theme-form-floating mt-3">
                    <input type="text" {...register('title')} className="form-control" placeholder={app.localizationsItems?.title}/>
                    <label>{app.localizationsItems?.title}</label>
                </div>
                <div className="form-floating theme-form-floating mt-3">
                    <input type="text" {...register('value')} className="form-control" placeholder={app.localizationsItems?.value}/>
                    <label>{app.localizationsItems?.value}</label>
                </div>
                <div className='d-flex justify-content-between mt-3 mb-3'>
                    <div>{app.localizationsItems?.value}</div>
                    <div className='admin-btns-cursor' onClick={() => append({ name: `` })}><i className='fa fa-plus'></i></div>
                </div>
                {fields?.map((el, index) =>
                    <div className='d-flex align-items-center gap-2 mt-2' key={index}>
                        <input placeholder={app.localizationsItems?.title} type="text" className='form-control' {...register(`elements[${index}].name`)}/>
                        <input placeholder={app.localizationsItems?.value} type="text" className='form-control' {...register(`elements[${index}].value`)}/>
                        <div className="fs-18"><i className='icon-delete' onClick={() => remove(index)}></i></div>
                    </div>
                )}
                <div className="row justify-content-end mt-3">
                    <div className="col-auto">
                        <div onClick={handleClose} className="btn btn-md bg-dark cart-button text-white">{app.localizationsItems?.cancel}</div>
                    </div>
                    <div className="col-auto">
                        <div onClick={e => customSubmit(e)} className="btn btn-md btn-animation">{app.localizationsItems?.save}</div>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default TraitEditModal