import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { Context } from '../../..';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import UploadImg from '../ui/UploadImg';
import PageServices from '../../../services/admin/PagesServices';

const DealsEditModal = observer(({
    show,
    setShow,
    banner,
    banners,
    index,
    setBanners,
    elem,
    id
}) => {
    const {app} = useContext(Context);

    const handleClose = () => setShow(false);

    const [loading, setLoading] = useState();

    const {register, control, setValue, handleSubmit, formState: {errors}} = useForm();
    
    useEffect(() => {
        if(banner){
            setValue('front_image', banner.front_image);
            setValue('description', banner.description);
            setValue('link', banner.link);
            setValue('button_text', banner.button_text);
        }
    }, [banner])

    const customSubmit = async (data) => {
        setLoading(true);
        
        banners[index] = data
        const newData = {
            parent_id: id,
            type: "opening-slider",
            value: {
                type: "opening-slider",
                title: elem.title,
                elements: [
                    ...banners
                ]
            }
        }

        const res = await PageServices.fields.update(elem.id, newData);
        
        if(res.statusCode === 200){
            setBanners([...newData.value.elements]);
            setShow(false);
        }else{
            toast.error(res.message);
        }
        setLoading(false);
    }

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            {loading &&
                <div className='form-loading'>
                    <div className="isLoading"></div>
                </div>
            }
            <form className='row' onSubmit={handleSubmit(customSubmit)}>
                <div className="col-12">
                    <div className="log-in-title mb-3">
                        <h2>{app.localizationsItems?.edit_banner}</h2>
                        <p>{app.localizationsItems?.image_format}</p>
                    </div>
                    <Controller
                        control={control}
                        name="front_image"
                        render={({field: {onChange, value}}) => (
                            <UploadImg onChange={onChange} value={value} $error={errors.image}/>
                        )}
                    />
                    <div className=" mt-3">
                        <textarea type="text" {...register('description')} className="form-control" placeholder={app.localizationsItems?.description}/>
                    </div>
                    <div className="form-floating theme-form-floating mt-3">
                        <input type="text" {...register('link')} className="form-control" placeholder={app.localizationsItems?.link_button}/>
                        <label>{app.localizationsItems?.link_button}</label>
                    </div>
                    <div className="form-floating theme-form-floating mt-3">
                        <input type="text" {...register('button_text')} className="form-control" placeholder={app.localizationsItems?.button_text}/>
                        <label>{app.localizationsItems?.button_text}</label>
                    </div>
                    <div className="row justify-content-end mt-3">
                        <div className="col-auto">
                            <div onClick={handleClose} className="btn btn-md bg-dark cart-button text-white">{app.localizationsItems?.cancel}</div>
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-md btn-animation">{app.localizationsItems?.save}</button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default DealsEditModal