import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { Context } from '../../..';
import AppServices from '../../../services/general/appServices';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';

const HelpModal = observer(({
    show,
    setShow
}) => {
    const {app} = useContext(Context);

    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState();

    const {register, control, setValue, handleSubmit, formState: {errors}} = useForm();

    const customSubmit = async (data) => {
        setLoading(true);

        const res = await AppServices.request(data);

        if(res.statusCode === 200){
            setLoading(false);
            toast.success(res.message)
            handleClose();
        }else{
            toast.error(res.message);
            setLoading(false);
        }
    }

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            {loading &&
                <div className='form-loading'>
                    <div className="isLoading"></div>
                </div>
            }
            <form className='row' onSubmit={handleSubmit(customSubmit)}>
                <div className="col-12">
                    <div className="log-in-title mb-3">
                        <h2>{app.localizationsItems?.send_us}</h2>
                        <p>{app.localizationsItems?.modal_description}</p>
                    </div>
                    <div className="form-floating theme-form-floating">
                        <input type="text" {...register('name')} required className="form-control" placeholder={app.localizationsItems?.name}/>
                        <label>{app.localizationsItems?.name}</label>
                    </div>
                    <div className="form-floating theme-form-floating mt-3">
                        <Controller defaultValue="" name="phone" control={control}
                                render={({field: {onChange, value}}) => (
                                <ReactInputMask onChange={onChange} value={value}
                                    mask="+7(999)999-99-99" maskChar="_">
                                    {
                                        inputProps => (
                                            <input type="tel" $error={errors.phone} className="form-control" placeholder={app.localizationsItems?.phone} {...inputProps}/>
                                        )
                                    }
                                </ReactInputMask>
                            )}
                            rules={{pattern: {
                                value: /(?:\+|\d)[\d\-() ]{9,}\d/g
                             }}}
                        />
                        <label>{app.localizationsItems?.phone}*</label>
                    </div>
                    <div className="form-floating theme-form-floating mt-3">
                        <textarea type="text" {...register('message')} required className="form-control" placeholder={app.localizationsItems?.your_question}/>
                        <label>{app.localizationsItems?.your_question}</label>
                    </div>
                    <div className="row justify-content-end mt-3">
                        <div className="col-auto">
                            <div onClick={handleClose} className="btn btn-md bg-dark cart-button text-white">{app.localizationsItems?.cancel}</div>
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-md btn-animation">{app.localizationsItems?.send}</button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default HelpModal