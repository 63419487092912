import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import img from "../../../images/placeholder.png"
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Context } from '../../..'
import DealsDeleteModal from '../modals/DealsDeleteModal'
import PageServices from '../../../services/admin/PagesServices'
import { toast } from 'react-toastify'
import AdvertisingBannerEditModal from '../modals/AdvertisingBannerEditModal'

const AdminBannerSection = observer(({item, id}) => {
    const {app} = useContext(Context);

    const [banners, setBanners] = useState();

    const navigate = useNavigate();

    const [showEdit, setShowEdit] = useState();
    const [deleteModal, setDeleteModal] = useState();
    
    const [isPublished, setIsPublished] = useState();

    const [deleteIndex, setDeleteIndex] = useState();

    useEffect(() => {
        if(item.elements){
            setBanners(item.elements)
        }
    }, [item])

    const handleDelete = async () => {
        banners[deleteIndex] = {
            title: null,
            description: null
        }

        const newData = {
            parent_id: id,
            type: "advertising-banner-2",
            value: {
                banner_type: "1",
                type: "advertising-banner-2",
                title: item.title,
                elements: [
                    ...banners
                ]
            }
        }

        const res = await PageServices.fields.update(item.id, newData);
        
        if(res.statusCode === 200){
            setBanners([...newData.value.elements]);
            setDeleteModal(false);
        }else{
            toast.error(res.message);
        }
    }

  return (
    banners &&
	<section className="banner-section">
		<div className="container-fluid-lg admin-btn-hover">
            <div className="category-item-btns">
                <div className="category-item-btns-item">
                    <i className={isPublished ? "icon-visibility" : "icon-visibility_off"}></i>
                </div>
                <div className="category-item-btns-item"><i className="icon-chevron-down"></i></div>
                <div className="category-item-btns-item"><i className="icon-chevron-up"></i></div>
                <div className="category-item-btns-item" onClick={() => setDeleteModal(true)}><i className="icon-delete"></i></div>
            </div>
			<div className="row gy-xl-0 gy-3">
				{banners[0] &&
					<div className="col-xl-6">
						<div className="banner-contain-3 admin-banner-hover">
							<img src={'https://shop-003-003.panama.kz'+banners[0].image} className="bg-img img-fluid position-absolute" alt=""
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                                loading='lazy'
                            />
							<div className="banner-detail banner-details-dark text-white p-center-left w-50 position-relative mend-auto">
								<div style={{color: banners[0].banner_font_color}}>
									<h6 className="ls-expanded text-uppercase">{banners[0].subtitle_top}</h6>
									<h3 className="mb-sm-3 mb-1">{banners[0].title}</h3>
									<h4>{banners[0].description}</h4>
									{banners[0].link && 
                                        <button 
                                            onClick={() => navigate(banners[0].link)} 
                                            className="btn theme-color bg-white btn-md fw-bold mt-sm-3 mt-1 mend-auto"
                                        >{banners[0].subtitle}</button>}
								</div>
							</div>
                            <div className='admin-banner-item-content'>
                                <div className='admin-banner-btn edit' onClick={() => {
                                    setDeleteIndex(0);
                                    setShowEdit(true)
                                    }}>{app.localizationsItems?.edit}
                                    <i className='icon-pen'></i>
                                </div>
                                <div className='admin-banner-btn delete' onClick={() => {setDeleteIndex(0); setDeleteModal(true)}}>{app.localizationsItems?.delete}
                                    <i className='icon-delete'></i>
                                </div>
                            </div>
						</div>
					</div>
				}
				{banners[1] &&
					<div className="col-xl-6">
						<div className="banner-contain-3 admin-banner-hover">
							<img src={'https://shop-003-003.panama.kz'+banners[1].image} className="bg-img img-fluid position-absolute" alt=""
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                                loading='lazy'
                            />
							<div className="banner-detail text-dark p-center-left w-50 position-relative mend-auto">
								<div style={{color: banners[1].banner_font_color}}>
									<h6 className=" ls-expanded text-uppercase">{banners[1].subtitle_top}</h6>
									<h3 className="mb-sm-3 mb-1">{banners[1].title}</h3>
									<h4 className="text-content">{banners[1].description}</h4>
									{banners[1].link && 
                                        <button 
                                            onClick={() => navigate(banners[1].link)} 
                                            className="btn theme-bg-color text-white btn-md fw-bold mt-sm-3 mt-1 mend-auto"
                                        >
                                            {banners[1].subtitle}
                                        </button>}
								</div>
							</div>
                            <div className='admin-banner-item-content'>
                                <div className='admin-banner-btn edit' 
                                    onClick={() => {
                                        setDeleteIndex(1);
                                        setShowEdit(true);
                                    }}
                                >{app.localizationsItems?.edit}
                                    <i className='icon-pen'></i>
                                </div>
                                <div className='admin-banner-btn delete' onClick={() => {setDeleteIndex(1); setDeleteModal(true)}}>{app.localizationsItems?.delete}
                                    <i className='icon-delete'></i>
                                </div>
                            </div>
						</div>
					</div>
				}
			</div>
		</div>
        {deleteModal &&
            <DealsDeleteModal
                show={deleteModal}
                setShow={setDeleteModal}
                successCallback={handleDelete}
            />
        }
        {showEdit &&
            <AdvertisingBannerEditModal
                show={showEdit}
                setShow={setShowEdit}
                banners={banners}
                setBanners={setBanners}
                index={deleteIndex}
                item={item}
                id={id}
            />
        }
	</section>
  )
})

export default AdminBannerSection