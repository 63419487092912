import React, { useContext, useState} from 'react'
import { useForm } from 'react-hook-form';
import Utils from '../../services/utils';
import AppServices from '../../services/general/appServices';
import img from "../../images/placeholder.png"
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';

const Form = observer(({item}) => {
    const {app} = useContext(Context);
    const [loading, setLoading] = useState(false);
    const {handleSubmit, register, reset, control, formState: {errors}} = useForm();

    const CustomSubmit = async (data) => {
        setLoading(true);
    
        const newData = {
            fields: {}
        };
    
        for (const key in data) {
            const temp = item.elements.find(it => it.name === key);
            if (temp) {
                newData.fields[temp.label] = data[key];
            }
        }
    
        const query = new URLSearchParams(window.location.search);
    
        if (query.toString()) {
            const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
            utmParams.forEach(param => {
                const value = query.get(param);
                if (value) {
                    newData[param] = value;
                }
            });
        } else {
            const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
            utmParams.forEach(param => {
                const cookieValue = Utils.getCookie(param);
                if (cookieValue) {
                    newData[param] = cookieValue;
                }
            });
        }
    
        const res = await AppServices.request(newData);

        if (res.statusCode === 200) {
            setTimeout(() => {
                reset();
            }, 3000);
            toast.success(res.message);
        } else {
            toast.error(res.message);
        }

        setLoading(false);
    };

  return (  
	<section className="contact-box-section section-b-space">
		<div className="container-fluid-lg">
			<div className="row g-lg-5 g-3">
				<div className="col-lg-6">
					<div className="left-sidebar-box">
						<div className="row">
							<div className="col-xl-12">
								<div className="contact-image">
									<img src={item.image} alt=""
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; 
                                        currentTarget.src = img
                                    }}
                                    loading='lazy'
                                    />
								</div>
							</div>
							<div className="col-xl-12">
								<div className="contact-title">
									<h3>{item.title}</h3>
								</div>

								<div className="contact-detail">
									<div className="row g-4">
                                        {item.items?.map((card, index) =>
                                            <div className="col-xxl-6 col-lg-12 col-sm-6" key={index}>
                                                <div className="contact-detail-box" dangerouslySetInnerHTML={{__html: card.description}}></div>
                                            </div>
                                        )}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-lg-6">
					<form className={loading ? "right-sidebar-box isLoading" : "right-sidebar-box"} onSubmit={handleSubmit(CustomSubmit)}>
                        <div className="title d-block">
                            <h2>{item.subtitle}</h2>
                        </div>
						<div className="row">
                            {item.elements?.map((card, index) =>
							<div className="col-xxl-6 col-lg-12 col-sm-6" key={index}>
								<div className="mb-md-4 mb-3 custom-form">
									<label className="form-label">{card.label}</label>
									<div className="custom-input">
										<input {...register(card.name)} type="text" className="form-control" placeholder={card.placeholder}/>
									</div>
								</div>
							</div>
                            )}
						</div>
						<button className="btn btn-animation btn-md fw-bold ms-auto">{app.localizationsItems?.send_message}</button>
					</form>
				</div>
			</div>
		</div>
	</section>
  )
})

export default Form