import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';
import { ReactSortable } from "react-sortablejs";
import UploadImgGallery from '../ui/UploadImgGallery';
import AdminCatalogServices from '../../../services/admin/AdminCatalogServices';
import { toast } from 'react-toastify';

const GalleryEditModal = observer(({
    show,
    setShow,
    images,
    setImages,
    id
}) => {
    const {app} = useContext(Context);
    const [loading, setLoading] = useState();

    const handleClose = () => setShow(false);

    const customSubmit = async() => {
        const newData = {
            gallery: images
        }

        const res = await AdminCatalogServices.products.update(id, newData);

        if(res.statusCode === 200){
            setShow(false);
        }else{
            toast.error(res.message);
        }
    }

  return (
    <Modal show={show} onHide={handleClose} centered size='xl'>
        <Modal.Header closeButton>
            <h2>{app.localizationsItems?.gallery_edit}</h2>
        </Modal.Header>
        <Modal.Body>
            {loading &&
                <div className='form-loading'>
                    <div className="isLoading"></div>
                </div>
            }
            {images && <ReactSortable
                list={images}
                setList={setImages}
                group="shared-group"
                className="gallery-grid"
            >
                {images.map((im, index) =>
                    <div key={im} className='gallery-grid-item'>
                        <img src={'https://shop-003-003.panama.kz'+im} loading='lazy'/>
                        <div className='gallery-delete'>
                            <i className='icon-delete'></i>
                        </div>
                    </div>
                )}
            </ReactSortable>}
            <div>
                <UploadImgGallery 
                    images={images}
                    setImages={setImages}
                />
            </div>
            <div className="row justify-content-end mt-3">
                <div className="col-auto">
                    <div onClick={handleClose} className="btn btn-md bg-dark cart-button text-white">{app.localizationsItems?.cancel}</div>
                </div>
                <div className="col-auto">
                    <div onClick={customSubmit} className="btn btn-md btn-animation">{app.localizationsItems?.save}</div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default GalleryEditModal