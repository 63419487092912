import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react'
import Advantages from '../../components/complex/Advantages';
import CategoriesSlider2 from '../../components/complex/CategoriesSlider2';
import BannerSection from '../../components/simple/BannerSection';
import BannerSection2 from '../../components/simple/BannerSection2';
import Newsletter from '../../components/complex/Newsletter';
import BlogSlider from '../../components/complex/BlogSlider';
import DayOffer from '../../components/complex/DayOffer';
import ProductBestsellers from '../../components/complex/ProductBestsellers';
import ProductsGrid from '../../components/complex/ProductsGrid';
import AboutUs from '../../components/complex/AboutUs';
import OurTeam from '../../components/complex/OurTeam';
import ReviewSection from '../../components/complex/ReviewSection';
import FaqComponent from '../../components/complex/FaqComponent';
import OurBusiness from '../../components/complex/OurBusiness';
import Excellences from '../../components/complex/Excellences';
import TextComponent from '../../components/simple/TextComponent';
import Partners from '../../components/complex/Partners';
import { Form, useNavigate, useParams } from 'react-router-dom';
import PopupModal from '../../components/modals/PopupModal';
import RecentViewProducts from '../../components/complex/RecentViewProducts';
import DynamicPaginationProducts from '../../components/complex/DynamicPaginationProducts';
import { Context } from '../..';
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import AppMobBanner from '../../components/admin/main/AppMobBanner';
import AdminMainBanner from '../../components/admin/main/AdminMainBanner';
import AdminCategoriesSlider from '../../components/admin/main/AdminCategoriesSlider';
import AdminBestDeals from '../../components/admin/main/AdminBestDeals';
import AdminProductsGrid from '../../components/admin/main/AdminProductsGrid';
import AdminBannerSection from '../../components/admin/main/AdminBannerSection';
import AdminBannerSection2 from '../../components/admin/main/AdminBannerSection2';
import AdminBlogSlider from '../../components/admin/main/AdminBlogSlider';
import AdminAdvantages from '../../components/admin/main/AdminAdvantages';
import AdminRecentViewProducts from '../../components/admin/main/AdminRecentViewProducts';

const AdminMainPage = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);
    const{lang: lang2} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}`);
            }
        }
    }, [app.lang])

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, 'main')

  return (
    <>
        {loading ?
            <div className="fullpage-loader">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            :
            <div>
                <AppMobBanner/>
                <div className='site-wrapper'>
                {items &&
                    items.elements?.map((elem, index) =>
                    <div key={index}>
                        {elem.type === "advantages" ?
                            <AdminAdvantages item={elem} id={items.id}/>
                            :
                        elem.type === "opening-4" ?
                            <AdminMainBanner item={elem} id={items.id}/>
                            :
                        elem.type === "product-categories" ?
                            <AdminCategoriesSlider item={elem} id={items.id}/>
                            :
                        elem.type === "product-categories-2" ?
                            <CategoriesSlider2 item={elem}/>
                            :
                        elem.type === "advertising-banner-2" ?
                            Number(elem.banner_type) === 1 ?
                                <AdminBannerSection item={elem}/>
                                :
                            Number(elem.banner_type) === 2 ?
                                <AdminBannerSection2 item={elem} id={items.id}/>
                                :
                            Number(elem.banner_type) === 3 ?
                                <Newsletter item={elem}/>
                                :
                                <></>
                            :
                        elem.type === "articles" ?
                            <AdminBlogSlider item={elem} id={items.id}/>
                            :
                        elem.type === "products" ?
                            elem.products_type === "recommended" ?
                                <DayOffer item={elem}/>
                                :
                            elem.products_type === "bestsellers" ?
                                <ProductBestsellers item={elem}/>
                                :
                                <AdminProductsGrid item={elem} id={items.id}/>
                            :
                        elem.type === "about" ?
                            <AboutUs elem={elem}/>
                            :
                        elem.type === "workers" ?
                            <OurTeam elem={elem}/>
                            :
                        elem.type === "reviews" ?
                            <ReviewSection elem={elem}/>
                            :
                        elem.type === "faq" ?
                            <FaqComponent elem={elem}/>
                            :
                        elem.type === "advantages-2" ?
                            <OurBusiness elem={elem}/>
                            :
                        elem.type === "steps" ?
                            <Excellences elem={elem}/>
                            :
                        elem.type === "text-component" ?
                            <TextComponent elem={elem}/>
                            :
                        elem.type === "opening-slider" ?
                            <AdminBestDeals elem={elem} id={items.id}/>
                            :
                        elem.type === "partners" ?
                            <Partners elem={elem}/>
                            :
                        elem.type === "form" ?
                            <Form item={elem}/>
                            :
                        elem.type === "popup-modal" ?
                            <PopupModal item={elem}/>
                            :
                        elem.type === "recent-products" ?
                            <AdminRecentViewProducts item={elem} id={items.id}/>
                            :
                        elem.type === "products-dynamic" ?
                            <DynamicPaginationProducts item={elem}/>
                            :
                            <></>
                        }
                    </div>
                    )
                }
                </div>
            </div>
        }
    </>
  )
})

export default AdminMainPage