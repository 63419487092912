import React, { useContext, useState } from 'react'
import ImageUpload from "../../../services/admin/imageUpload";
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { Context } from '../../..';

const UploadImgGallery = observer(({setImages, images}) => {
    const {app} = useContext(Context);
    const [loading, setLoading] = useState();

    const uploadImg = async (e) => {
        const currentFile = e.target.files[0];
        setLoading(true);

        const res = await ImageUpload.upload(currentFile);

        if (res.statusCode === 200) {
            toast.success(res.message)
            setImages([...images, res.content.value]);
        } else {
            toast.error(res.message)
        }
        setLoading(false);
    }

  return (
    <div className='upload-img mini'>
        <input type="file" name="files" accept="image/jpeg, image/png, image/svg, image/gif, image/webp, image/jpg, text/css, text/js" 
            onChange={e => uploadImg(e)}
        />
        <div className='upload-img-wrapper'>
            <i className='icon-download'></i>
            <div>{app.localizationsItems?.upload}</div>
        </div>
        {loading &&
            <div className='form-loading'>
                <div className="isLoading"></div>
            </div>
        }
    </div>
  )
})

export default UploadImgGallery