import React, { useContext } from 'react'
import { useEffect, useState} from 'react'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import { NavLink, useNavigate } from 'react-router-dom'
import AdminProductFeatures from '../parts/AdminProductFeatures'
import RightInfo from '../../simple/RightInfo'
import AdminProductSlider from '../parts/AdminProductSlider'
import AdminProductDetailItem from '../parts/AdminProductDetailItem'

const AdminProductLeftSidebar = observer(({item, fetchInfo, ids}) => {
	const [features, setFeatures] = useState();
    const navigate = useNavigate();

    const {app} = useContext(Context);

	useEffect(() => {
		if(item){
			setFeatures(item.features)
		}
	}, [item])

  return (
	<section className="product-section">
		<div className="container-fluid-lg">
            <div className="back-button2" onClick={() => navigate(-1)}>
                <span><i className="fa-solid fa-arrow-left"></i>{app.localizationsItems?.back}</span>
            </div>
			<div className="row">
				<div className="col-xxl-9 col-xl-8 col-lg-7 wow fadeInUp">
					<div className="row g-4">
						<div className="col-xl-6 wow fadeInUp">
							<AdminProductSlider gallery={item.gallery} image={item.image} id={item.id}/>
						</div>
						<div className="col-xl-6 wow fadeInUp">
							<div className="right-box-contain">
                                <AdminProductDetailItem item={item} fetchInfo={fetchInfo}/>
								<AdminProductFeatures
                                    features={features}
                                    fetchInfo={fetchInfo}
                                    item={item}
                                />
                                {item.additional_product &&
                                    <NavLink to={`/${app.lang}/products/${item.additional_product.slug}`} className='product-present'>
                                        <img src={item.additional_product.preview_image} alt="" loading='lazy'/>
                                        <div>
                                            <div className='product-present-title'>{app.localizationsItems?.as_gift}</div>
                                            <div>{item.additional_product.title}</div>
                                        </div>
                                    </NavLink>
                                }
							</div>
						</div>
					</div>
				</div>

				<div className="col-xxl-3 col-xl-4 col-lg-5 d-none d-lg-block wow fadeInUp">
					<RightInfo ids={ids}/>
				</div>
			</div>
		</div>
	</section>
  )
})

export default AdminProductLeftSidebar