import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { MAIN_PAGE_ROUTE } from '../../navigation/Constants'
import AppServices from '../../services/general/appServices'
import Widgets from '../simple/Widgets'
import ToTop from '../simple/ToTop'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'

const Footer = observer(() => {
    const {app} = useContext(Context);

    const [items, setItems] = useState();

	const [logo, setLogo] = useState();
	const [text, setText] = useState();
	const [socials, setSocials] = useState();
	const [address, setAddress] = useState();
	const [phone, setPhone] = useState();
	const [email, setEmail] = useState();
    const [widgets, setWidgets] = useState();
    const [workTime, setWorkTime] = useState();

    const fetchMenu = async () => {
        const res = await AppServices.menu('bottom-menu');
        if(res.statusCode === 200){
            setItems(res.content)
        }
    }
    
    useEffect(()=> {
        fetchMenu()
    }, [])

	useEffect(() => {
        if(app.settings){
			setLogo(app.settings.find(it => it.type === "logo-white")?.value);
			setText(app.settings.find(it => it.type === "footer_text"));
			setSocials(app.settings.find(it => it.type === "socials"));
			setAddress(app.settings.find(it => it.type === "address"));
			setPhone(app.settings.find(it => it.type === "phone"));
			setEmail(app.settings.find(it => it.type === "email"));
			setWorkTime(app.settings.find(it => it.type === "work-time"));
            setWidgets(app.settings.find(it => it.type === "widgets")?.value);
        }
    }, [app.settings])

  return (
	<footer className="section-t-space footer-section-2">
		<div className="container-fluid-lg">
			<div className="main-footer">
				<div className="row g-md-4 gy-sm-5 gy-2">
					<div className="col-xxl-3 col-xl-4 col-sm-6">
						<NavLink to={`/${app.lang}`} className="foot-logo">
							<img src={logo} className="img-fluid" alt="Logo" loading='lazy'/>
						</NavLink>
						{text?.value.map((em, index) => 
                            <p key={index} className="information-text">{em}</p>
                        )}
						{socials?.value.find(el => el.value) &&
							<ul className="social-icon">
								{socials.value.map((social, index) => 
									social.value &&
									<li key={index}>
										<NavLink to={social.value} target="_blank">
											<i className={
												social.type === "facebook" ? "fab fa-facebook-f"
												:
												social.type === "twitter" ? "fab fa-twitter"
												:
												social.type === "instagram" ? "fab fa-instagram"
												:
												social.type === "telegram" ? "fab fa-telegram"
												:
												social.type === "whatsapp" ? "fab fa-whatsapp"
                                                :
												social.type === "youtube" ? "fab fa-youtube"
												:
												social.type === "tiktok" ? "fab fa-tiktok"
												:
												"fab fa-facebook-f"
											}></i>
										</NavLink>
									</li>
								)}
							</ul>

						}
					</div>

					{items?.map((item, index) => 
						<div className="col-xxl-2 col-xl-4 col-sm-6" key={index}>
							<div className="footer-title">
								<h4>{item.title}</h4>
							</div>
							<ul className="footer-list footer-contact mb-sm-0 mb-3">
								{item.items?.map((el, index) =>
									<li key={index}>
										<NavLink to={el.value} className="footer-contain-2" type={el.type}>
											<i className="fas fa-angle-right"></i>{el.title}</NavLink>
									</li>
								)}
							</ul>
						</div>
					)}

					<div className="col-xxl-3 col-xl-4 col-sm-6">
						<div className="footer-title">
							<h4>{app.localizationsItems?.information_shop}</h4>
						</div>
						<ul className="footer-address footer-contact">
							{address?.value?.map((el, index) =>
								<li key={index}>
									<div className="inform-box flex-start-box">
										<i className='fa-solid fa-location-dot mr-3'></i>
										<p>{el}</p>
									</div>
								</li>
                            )}
							{workTime?.value?.map((el, index) =>
								<li key={index}>
									<div className="inform-box flex-start-box">
										<i className='fa-solid fa-business-time mr-3'></i>
										<div dangerouslySetInnerHTML={{__html: el}}></div>
									</div>
								</li>
                            )}
							{phone?.value?.map((el, index) =>
								<li key={index}>
									<div className="inform-box">
										<i className="fa fa-phone mr-3"></i>
										<p>
											<a href={`tel:${el}`}>{el}</a>
										</p>
									</div>
								</li>
                            )}
							{email?.value?.map((el, index) =>
								<li key={index}>
									<div className="inform-box">
										<i className="fa fa-mail-bulk mr-3"></i>
										<p>
											<a href={`mailto:${el}`}>{el}</a>
										</p>
									</div>
								</li>
                            )}
						</ul>
					</div>
				</div>
			</div>

			<div className="sub-footer section-small-space">
				<div className="left-footer">
					<p>© Panama.kz</p>
				</div>
			</div>
		</div>
        <ToTop widgets={widgets}/>  
        {widgets && <Widgets item={widgets}/>}
	</footer>
  )
})

export default Footer