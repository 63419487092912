import React, { useContext } from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import CatalogServices from '../../services/general/catalogServices'
import { NavLink } from 'react-router-dom'
import Utils from '../../services/utils';
import { Context } from '../..';
import img from "../../images/placeholder.png"
import { observer } from 'mobx-react-lite';

const RightInfo = observer(({
    ids
}) => {
    const {app} = useContext(Context);

    const {
        items:populars,
    } = useFetchItems(CatalogServices.products.index, ids ? ids : `type=popular`)

  return (
    <div className="right-sidebar-box">
		<div className="category-menu">
			<h3>{ids ? app.localizationsItems?.similar_products : app.localizationsItems?.popular_products}</h3>
			<ul className="product-list product-right-sidebar border-0 p-0">
                {populars?.items?.slice(0,4).map((card, index) =>
					<li key={index}>
						<div className="offer-product">
							<NavLink to={`/${app.lang}/products/${card.slug}`} className="offer-image">
								<img src={card.image ? card.image : img} className="img-fluid" alt=""
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; 
                                        currentTarget.src = img
                                    }}
                                    loading='lazy'
                                />
							</NavLink>
							<div className="offer-detail">
								<div>
									<NavLink to={`/${app.lang}/products/${card.slug}`}>
										<h6 className="name">{card.title}</h6>
									</NavLink>
                                    {(!card.price || card.price == 0) ?
                                        <h6 className="price theme-color">{app.localizationsItems?.not_specified}</h6> 
                                        :
										<h6 className="price theme-color">{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</h6>
                                    }
								</div>
							</div>
						</div>
					</li>
                )}
			</ul>
		</div>
    </div>
  )
})

export default RightInfo