import React, { useContext, useState } from 'react'
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';
import DescriptionEditModal from '../modals/DescriptionEditModal';
import DeleteModal from '../modals/DeleteModal';
import PageServices from '../../../services/admin/PagesServices';

const AdminSampleDescription = observer(({item, fetchItems}) => {
    const {app} = useContext(Context);

    const [showEdit, setShowEdit] = useState();
    const [deleteModal, setDeleteModal] = useState();

  return (
    <>
        <section className='blog-section section-b-space admin-banner-hover'>
            <div className="container-fluid-lg">
                <div dangerouslySetInnerHTML={{__html: item?.description}}></div>
            </div>
            <div className='admin-banner-item-content'>
                <div className='admin-banner-btn edit' onClick={() => setShowEdit(true)}>{app.localizationsItems?.edit}
                    <i className='icon-pen'></i>
                </div>
                <div className='admin-banner-btn delete' onClick={() => setDeleteModal(true)}>{app.localizationsItems?.delete}
                    <i className='icon-delete'></i>
                </div>
            </div>
        </section>
        {showEdit &&
            <DescriptionEditModal
                show={showEdit}
                setShow={setShowEdit}
                card={item}
                fetchInfo={fetchItems}
            />
        }
        {deleteModal &&
            <DeleteModal
                show={deleteModal}
                setShow={setDeleteModal}
                method={PageServices.delete}
                data={item.id}
                successCallback={fetchItems}
            />
        }
    </>
  )
})

export default AdminSampleDescription