import React from 'react'
import img from "../../images/placeholder.png"
import PartnersServices from '../../services/general/partnersServices'
import useFetchItems from '../../hooks/useFetchItems'
import Slider from 'react-slick'

const Partners = ({elem}) => {
    const {
        items,
    } = useFetchItems(PartnersServices.index)

    const settings = {
        infinity:false, 
        slidesToScroll: 1,
        slidesToShow: 6,
        responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
        ]
    }

  return (
    <div className='section-lg-space'>
        <div className='container-fluid-lg'>
            <div className="title">
                <h2>{elem.title}</h2>
            </div>
        </div>
        <div className="partners">
            <div className="container-fluid-lg">
                <Slider className="slider-3 arrow-slider" {...settings}>
                    {items?.items?.map((card, index) =>
                        <div key={index} className='partners-item'>
                            <img src={card.image} alt="" 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                                loading='lazy'
                            />
                        </div>
                    )}
                </Slider>
            </div>
        </div>
    </div>
  )
}

export default Partners