import http from "./http-common"
import {deleteData, getData, postData} from "../handlers";

export default class AdminCatalogServices {
    static categories = {
        index: (queryString) => {
            return getData(http, `/catalog/categories${queryString ? `?${queryString}` : ""}`);
        },

        tree: (queryString) => {
            return getData(http, `/catalog/categories/tree${queryString ? `?${queryString}` : ""}`);
        },

        view: (id, queryString) => {
            return getData(http, `/catalog/categories/${id}${queryString ? `${queryString}` : ""}`);
        },

        store: (data) => {
            return postData(http, `/catalog/categories/store`, data);
        },

        update: (id, data) => {
            return postData(http, `/catalog/categories/${id}`, data);
        },

        delete: (id) => {
            return deleteData(http, `/catalog/categories/${id}`);
        },

        switchPosition: (id, data) => {
            return postData(http, `/catalog/categories/switchPosition`, data);
        },

        exportByPage: (queryString) => {
            return getData(http, `/catalog/categories/export${queryString ? `?${queryString}` : ""}`);
        },

        items: {
            switchPosition: (parentId, data) => {
                return postData(http, `/catalog/categories/${parentId}/items/switchPosition`, data);
            },
    
            changePosition: (parentId, data) => {
                return postData(http, `/catalog/categories/${parentId}/items/changePosition`, data);
            },
    
            massUpdatePosition: (data) => {
                return postData(http, `/catalog/categories/items/massUpdatePosition`, data);
            }
        },

        localizations: {
            index: (id) => {
                return getData(http, `/catalog/categories/${id}/localizations`);
            },  
            view: (parentId, id) => {
                return getData(http, `/catalog/categories/${parentId}/localizations/${id}`);
            },
            store: (id, data) => {
                return postData(http, `/catalog/categories/${id}/localizations/store`, data);
            },
            update: (parentId, id, data) => {
                return postData(http, `/catalog/categories/${parentId}/localizations/${id}`, data);
            },
            delete: (parentId, id) => {
                return deleteData(http, `/catalog/categories/${parentId}/localizations/${id}`);
            },
        }
    }

    static products = {
        index: (queryString) => {
            return getData(http, `/catalog/products${queryString ? `?${queryString}` : ""}`);
        },

        view: (id, queryString) => {
            return getData(http, `/catalog/products/${id}${queryString ? `${queryString}` : ""}`);
        },

        store: (data) => {
            return postData(http, `/catalog/products/store`, data);
        },

        copy: (id) => {
            return getData(http, `/catalog/products/${id}/copy`);
        },

        update: (id, data) => {
            return postData(http, `/catalog/products/${id}`, data);
        },

        import: (data) => {
            return postData(http, `/catalog/products/import`, data);
        },

        delete: (id) => {
            return deleteData(http, `/catalog/products/${id}`);
        },

        exportByPage: (queryString) => {
            return getData(http, `/catalog/products/export${queryString ? `?${queryString}` : ""}`);
        },

        localizations: {
            index: (id) => {
                return getData(http, `/catalog/products/${id}/localizations`);
            },  
            view: (parentId, id) => {
                return getData(http, `/catalog/products/${parentId}/localizations/${id}`);
            },
            store: (id, data) => {
                return postData(http, `/catalog/products/${id}/localizations/store`, data);
            },
            update: (parentId, id, data) => {
                return postData(http, `/catalog/products/${parentId}/localizations/${id}`, data);
            },
            delete: (parentId, id) => {
                return deleteData(http, `/catalog/products/${parentId}/localizations/${id}`);
            },
        }
    }

    static deliveries = {
        index: () => {
            return getData(http, `/catalog/orderDeliveries`);
        },

        view: (id) => {
            return getData(http, `/catalog/orderDeliveries/${id}`);
        },

        store: (data) => {
            return postData(http, `/catalog/orderDeliveries/store`, data);
        },

        update: (id, data) => {
            return postData(http, `/catalog/orderDeliveries/${id}`, data);
        },

        delete: (id) => {
            return deleteData(http, `/catalog/orderDeliveries/${id}`);
        },

        switchPosition: (data) => {
            return postData(http, `/catalog/orderDeliveries/switchPosition`, data);
        }
    }

    static payments = {
        index: () => {
            return getData(http, `/catalog/orderPayments`);
        },

        view: (id) => {
            return getData(http, `/catalog/orderPayments/${id}`);
        },

        store: (data) => {
            return postData(http, `/catalog/orderPayments/store`, data);
        },

        update: (id, data) => {
            return postData(http, `/catalog/orderPayments/${id}`, data);
        },

        delete: (id) => {
            return deleteData(http, `/catalog/orderPayments/${id}`);
        },

        switchPosition: (data) => {
            return postData(http, `/catalog/orderPayments/switchPosition`, data);
        }
    }
}