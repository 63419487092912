import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { Context } from '../../..';
import { observer } from 'mobx-react-lite'

const DealsDeleteModal = observer(({
    show,
    setShow,
    successCallback
}) => {
    const {app} = useContext(Context);
    const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
            <h2>{app.localizationsItems?.delete_item}</h2>
        </Modal.Header>
        <Modal.Body>
            <p>{app.localizationsItems?.deleted_forever}</p>
            <div className="row justify-content-end mt-3">
                <div className="col-auto">
                    <div onClick={handleClose} className="btn btn-md bg-dark cart-button text-white">{app.localizationsItems?.cancel}</div>
                </div>
                <div className="col-auto">
                    <div onClick={successCallback} className="btn btn-md btn-animation">{app.localizationsItems?.delete}</div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default DealsDeleteModal