import React from 'react'
import img from "../../images/placeholder.png"

const OurBusinessCard = ({card}) => {
  return (
    <div className="clint-contain">
        <div className="client-icon">
            <img src={card.image} alt=""
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
                loading='lazy'
            />
        </div>
        <h2>{card.counter}</h2>
        <h4>{card.title}</h4>
        <p>{card.subtitle}</p>
    </div>
  )
}

export default OurBusinessCard