import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom';
import img from "../../../images/placeholder.png"
import { observer } from 'mobx-react-lite';
import { Context } from '../../..';
import DealsEditModal from '../modals/DealsEditModal';
import PageServices from '../../../services/admin/PagesServices';
import DealsDeleteModal from '../modals/DealsDeleteModal';
import { toast } from 'react-toastify';

const AdminDealsItem = observer(({
    card,
    elem,
    banners,
    setBanners,
    index,
    id
}) => {
    const {app} = useContext(Context);

    const [isPublished, setIsPublished] = useState();

    const [showEdit, setShowEdit] = useState();
    const [deleteModal, setDeleteModal] = useState()

    const handleDelete = async () => {
        const newArray = banners.filter((el, ind) => ind !== index);

        const newData = {
            parent_id: id,
            type: "opening-slider",
            value: {
                type: "opening-slider",
                title: elem.title,
                elements: [
                    ...newArray
                ]
            }
        }

        const res = await PageServices.fields.update(elem.id, newData);
        
        if(res.statusCode === 200){
            setBanners(newArray);
            setDeleteModal(false);
        }else{
            toast.error(res.message);
        }
    }

  return (
    <>
        <div className="offer-banner admin-banner-hover">
            <img src={'https://shop-003-003.panama.kz'+card.front_image} effect="blur" className="img-fluid bg-img" alt=""
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img
                    }}
                loading='lazy'
            />
            {card.description && <div className="banner-detail" 
                    dangerouslySetInnerHTML={{__html: card.description}}
            ></div>}
            <div className="offer-box">
                {card.link && <NavLink to={card.link} className="btn-category btn theme-bg-color text-white">{card.button_text}</NavLink>}
            </div>
            <div className='admin-banner-item-content'>
                <div className='admin-banner-btn change'>{app.localizationsItems?.published}
                    <i className={isPublished ? 'icon-visibility' : 'icon-visibility_off'}></i>
                </div>
                <div className='admin-banner-btn edit' onClick={() => setShowEdit(true)}>{app.localizationsItems?.edit}
                    <i className='icon-pen'></i>
                </div>
                <div className='admin-banner-btn delete' onClick={() => setDeleteModal(true)}>{app.localizationsItems?.delete}
                    <i className='icon-delete'></i>
                </div>
            </div>
        </div>
        {showEdit &&
            <DealsEditModal
                show={showEdit}
                setShow={setShowEdit}
                banner={card}
                banners={banners}
                elem={elem}
                setBanners={setBanners}
                index={index}
                id={id}
            />
        }
        {deleteModal &&
            <DealsDeleteModal
                show={deleteModal}
                setShow={setDeleteModal}
                successCallback={handleDelete}
            />
        }
    </>
  )
})

export default AdminDealsItem