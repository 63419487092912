import React, { useContext, useEffect, useState } from 'react'
import Slider from "react-slick";
import useFetchItems from '../../../hooks/useFetchItems';
import ArticleServices from '../../../services/general/articleServices';
import AdminBlogCard from '../parts/AdminBlogCard';
import ResizebaleInput from '../ui/ResizebleInput';
import PageServices from '../../../services/admin/PagesServices';
import { toast } from 'react-toastify';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';

const AdminBlogSlider = observer(({item, id}) => {
    const {app} = useContext(Context);
    const { 
        items,
        fetchItems
    } = useFetchItems(ArticleServices.index);
    
    const [isPublished, setIsPublished] = useState();
    
    const [responsive, setResponsive] = useState(3);

    useEffect(() => {
        if(app.mobView){
            setResponsive(1)
        }else{
            setResponsive(3)
        }
    }, [app.mobView]);

    const changeTitle = async (name) => {
        const newData = {
            parent_id: id,
            type: "articles",
            value: {
                type: "articles",
                elements: null,
                title: name
            }
        }

        const res = await PageServices.fields.update(item.id, newData);
        
        if(res.statusCode !== 200){
            toast.error(res.message);
        }
    }

  return (
	<section className="blog-section section-b-space">
		<div className="container-fluid-lg">
			<div className="category-item-top title">
                <ResizebaleInput title={item?.title} changeTitle={changeTitle} isPublished={isPublished}/>
                <div className="category-item-btns">
                    <div className="category-item-btns-item">
                        <i className='fa fa-plus'></i>
                    </div>
                    <div className="category-item-btns-item">
                        <i className={isPublished ? "icon-visibility" : "icon-visibility_off"}></i>
                    </div>
                    <div className="category-item-btns-item"><i className="icon-chevron-down"></i></div>
                    <div className="category-item-btns-item"><i className="icon-chevron-up"></i></div>
                    <div className="category-item-btns-item"><i className="icon-delete"></i></div>
                </div>
			</div>
            {items?.items &&
            	<Slider className="slider-3 arrow-slider" 
                    infinite={false} 
                    slidesToScroll={1}
                    slidesToShow={responsive}
                    responsive = {[{
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2,
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                    ]}
                >
                    {items.items.map((card, index) =>
                        <AdminBlogCard card={card} key={card.id} fetchItems={fetchItems}/>
                    )}
                </Slider>
            }
		</div>
	</section>
  )
})

export default AdminBlogSlider