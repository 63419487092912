import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Context } from '../..';
import Utils from '../../services/utils';
import img from "../../images/placeholder.png"
import UserServices from '../../services/general/userServices';
import { observer } from 'mobx-react-lite';
import DeleteProductModal from '../modals/DeleteProductModal';
import { toast } from 'react-toastify';

const BasketProductTr = observer(({card, setLoading}) => {
    const [count, setCount] = useState(card.count);
    const [deleteModal, setDeleteModal] = useState();

    const {app, user} = useContext(Context);

    const changeCount = async (e) => {
        const temp = count;
        setCount(Number(e.target.value));

        const data = {
            uid: user.uid,
            product_id: card.id,
            count: e.target.value,
            fields: card.fields,
            price_config: card.price_config
        }

        const res = await UserServices.cart.update(data);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16))
        }else{
            setCount(temp);
        }
    }

    const plusCount = async () => {
        const temp = count;
        setCount(count+1);

        const data = {
            uid: user.uid,
            product_id: card.id,
            count: count+1,
            fields: card.fields,
            price_config: card.price_config
        }

        const res = await UserServices.cart.update(data);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16))
        }else{
            setCount(temp);
        }
    }

    const minusCount = async () => {
        if(count > 1){
            const temp = count;
            setCount(count-1);
            const data = {
                uid: user.uid,
                product_id: card.id,
                count: count-1,
                fields: card.fields,
                price_config: card.price_config
            }
    
            const res = await UserServices.cart.update(data);
    
            if(res.statusCode === 200){
                user.setBasketChange(Math.random().toString(16))
            }else{
                setCount(temp);
            }
        }
    }

    const deleteItem = async () => {
        setLoading(true);

        const data = {
            uid: user.uid,
            product_id: card.id,
            fields: card.fields,
            price_config: card.price_config
        }

        const res = await UserServices.cart.remove(data);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16));
            setLoading(false);
            setDeleteModal(false)
        }else{
            toast.error(res.message)
        }
    }

  return (
    <>
        <tr className="product-box-contain">
            <td className="product-detail">
                <div className="product border-0">
                    {card.bonus && 
                        <span className='cart-bonus'>{card.bonus.number}{card.bonus.type == 1 ? '%' : ''} 
                            <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.72394 2.18445V3.258H2.08998V9.99988H0.748047V2.18445H5.72394ZM1.68203 5.18501H3.64663C4.24066 5.18501 4.7488 5.28521 5.17106 5.48561C5.59333 5.68243 5.91539 5.95976 6.13726 6.31761C6.35912 6.67546 6.47006 7.09235 6.47006 7.56829C6.47006 7.92614 6.40743 8.25357 6.28219 8.55059C6.15694 8.8476 5.97265 9.10525 5.72931 9.32354C5.48597 9.53825 5.18896 9.70465 4.83826 9.82274C4.49115 9.94083 4.09394 9.99988 3.64663 9.99988H0.748047V2.18445H2.10072V8.9317H3.64663C3.98658 8.9317 4.26571 8.86907 4.48399 8.74383C4.70228 8.615 4.86331 8.44681 4.96709 8.23926C5.07445 8.03171 5.12812 7.80447 5.12812 7.55756C5.12812 7.3178 5.07445 7.09951 4.96709 6.90269C4.86331 6.70587 4.70228 6.54842 4.48399 6.43033C4.26571 6.31224 3.98658 6.2532 3.64663 6.2532H1.68203V5.18501Z" fill="white"/>
                                <path d="M0.748047 0.00146484H5.7229V1.09912H0.748047V0.00146484Z" fill="white"/>
                            </svg>
                        </span>
                    }
                    <NavLink to={`/${app.lang}/products/${card.slug}`} className="product-image">
                        <img src={card.image} className="img-fluid" alt=""
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img
                            }}
                            loading='lazy'
                        />
                    </NavLink>
                    <div className="product-detail">
                        <ul>
                            <li className="name">
                                <NavLink to={`/${app.lang}/products/${card.slug}`}>{card.title}</NavLink>
                            </li>
                            {card.fields?.map((filed, index) =>
                                <li className="text-content" key={index}><span
                                className="text-title">{filed.name}:</span> {filed.value}</li>
                            )}
                        </ul>
                    </div>
                </div>
            </td>

            <td className="price">
                <h4 className="table-title text-content">{app.localizationsItems?.price} {app.localizationsItems?.currency}</h4>
                <h5>{(!card.price || card.price == 0) ?
                    <span className="theme-color">{app.localizationsItems?.not_specified}</span> 
                    :
                    <>{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</>
                }
                {card.old_price && <del className="text-content">{Utils.numberWithSpaces(card.old_price)} {app.localizationsItems?.currency}</del>}</h5>
                {card.old_price && <h6 className="theme-color">{app.localizationsItems?.you_saved} : {Utils.numberWithSpaces(Number(card.old_price) - Number(card.price))} {app.localizationsItems?.currency}</h6>}
            </td>

            <td className="quantity">
                <h4 className="table-title text-content">{app.localizationsItems?.qty}</h4>
                <div className="quantity-price">
                <div className="cart_qty">
                    <div className="input-group"> 
                        <button type="button" className="btn qty-left-minus" onClick={minusCount}
                            data-type="minus" data-field="">
                            <i className="fa fa-minus ms-0" aria-hidden="true"></i>
                        </button>
                        <input className="form-control input-number qty-input" type="text" value={count} onChange={e => changeCount(e)}/>
                        <button type="button" className="btn qty-right-plus" onClick={plusCount}
                            data-type="plus" data-field="">
                            <i className="fa fa-plus ms-0" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                </div>
            </td>

            <td className="subtotal">
                <h4 className="table-title text-content">{app.localizationsItems?.general}</h4>
                <h5>{Utils.numberWithSpaces(Number(card.price) * Number(card.count))} {app.localizationsItems?.currency}</h5>
            </td>

            <td className="save-remove">
                <h4 className="table-title text-content">{app.localizationsItems?.action}</h4>
                <div className="close_button" onClick={() => setDeleteModal(true)}>{app.localizationsItems?.delete}</div>
            </td>
            {deleteModal && <DeleteProductModal show={deleteModal} setShow={setDeleteModal} deleteItem={deleteItem}/>}
        </tr>
        {card.additional_product && <tr className='product-present-tr'>
            <td className="product-detail" colSpan={5}>
                <div className="product border-0">
                    <NavLink to={`/${app.lang}/products/${card.slug}`} className="product-image">
                            <img src={card.additional_product.preview_image} className="img-fluid" alt=""
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                                loading='lazy'
                            />
                    </NavLink>
                    <div className="product-detail">
                            <ul>
                                <li className="name">
                                    <NavLink to={`/${app.lang}/products/${card.slug}`}>{card.additional_product.title}</NavLink>
                                    <div className='text-present'>{app.localizationsItems?.as_gift}</div>
                                </li>
                            </ul>
                    </div>
                </div>
            </td>
        </tr>}
    </>
  )
})

export default BasketProductTr