import React, { useContext } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import Utils from '../../../services/utils';
import useFetchInfo from '../../../hooks/useFetchInfo.hook';
import UserServices from '../../../services/general/userServices';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';

const OrderProductDetail = observer(() => {
    const {id} = useParams();

    const {app} = useContext(Context);

    const {info, loading} = useFetchInfo(UserServices.orders.view, id);

  return (
    info &&
    <div className="order-product">
        <NavLink to={`/${app.lang}/user/orders`}>
            <div className='icon-back'>
                <i className='fa fa-arrow-left'></i>
                {app.localizationsItems?.back}
            </div>
        </NavLink>
        <div className='order-wrapper'>
            <div className="order-container">
                <div className="order-detail">
                    <h4>{app.localizationsItems?.order} №<span>{info.id}</span> 
                        <span className='status-span'
                            style={{background: info.status?.color}}
                        >{info.status?.name}</span>
                    </h4>
                    <h6 className="text-content">
                        {info.description}
                    </h6>
                </div>
            </div>
            <div className='product-order-text'>
                <div className='date'>{info.created_at && Utils.generateOrderDate(info.created_at)}</div>
                {info.delivery && <div className="text-product"> <span>{app.localizationsItems?.delivery_variant}:</span> {info.delivery?.name} </div>}
                {info.products?.length && <div className="text-product"> <span>{app.localizationsItems?.qty_goods}:</span> {info.products.length}</div>}
                {info.payment && <div className="text-product"> <span>{app.localizationsItems?.payment_variant}:</span> {info.payment.name}</div>}
                {info.address?.full_address && <div className="text-product"> <span>{app.localizationsItems?.address}:</span> {info.address.full_address}</div>}
            </div>
            <div className='product-order-text padding'>
                <div className='date'></div>
                {info.sub_total_price ? <div className="text-product"> <span>{app.localizationsItems?.subtotal}:</span> {Utils.numberWithSpaces(info.sub_total_price)} {app.localizationsItems?.currency}</div> : <></>}
                {info.discount_price ? <div className="text-product"> <span>{app.localizationsItems?.discount}:</span> -{Utils.numberWithSpaces(info.discount_price)} {app.localizationsItems?.currency}</div> : <></>}
                {info.bonus_used ? <div className="text-product"> <span>{app.localizationsItems?.bonuses_used}:</span> {Utils.numberWithSpaces(info.bonus_used)} {app.localizationsItems?.currency}</div> : <></>}
                {info.total_price ? <div className="text-product"> <span>{app.localizationsItems?.total}:</span> {Utils.numberWithSpaces(info.total_price)} {app.localizationsItems?.currency}</div> : <></>}
                {info.bonus_gained ? <div className="text-product"> <span>{app.localizationsItems?.will_credited}:</span> + {Utils.numberWithSpaces(info.bonus_gained)} Б</div> : <></>}
            </div>
            {info.products?.map((elem, index) =>
                <div className='product-detail-wrapper' key={index}>
                    <div className="product-order-detail">
                        <NavLink to={`/${app.lang}/products/${elem.slug}`} className="order-image">
                            <img src={elem.image} alt="" loading='lazy'/>
                        </NavLink>

                        <div className="order-wrap">
                        <NavLink to={`/${app.lang}/products/${elem.slug}`} >
                            <h3>{elem.title}</h3>
                        </NavLink>
                        <ul className="product-size">
                            <li>
                                <div className="size-box">
                                    <h6 className="text-content">{app.localizationsItems?.price} : </h6>
                                    <h5>{Utils.numberWithSpaces(elem.price)} {app.localizationsItems?.currency}</h5>
                                </div>
                            </li>
                            <li>
                                <div className="size-box">
                                    <h6 className="text-content">{app.localizationsItems?.qty} : </h6>
                                    <h5>{elem.count}</h5>
                                </div>
                            </li>
                            {elem.fields?.map((it, index) =>
                                <li key={index}>
                                    <div className="size-box">
                                        <h6 className="text-content">{it.name} : </h6>
                                        <h5>{it.value}</h5>
                                    </div>
                                </li>
                            )}
                        </ul>
                        </div>
                    </div>
                    {elem.additional_product &&
                        <NavLink to={`/${app.lang}/products/${elem.additional_product.slug}`} className='product-present'>
                            <img src={elem.additional_product.preview_image} alt="" loading='lazy'/>
                            <div>
                                <div className='product-present-title'>{app.localizationsItems?.as_gift}</div>
                                <div>{elem.additional_product.title}</div>
                            </div>
                        </NavLink>
                    }
                </div>
            )}
        </div>
    </div>
  )
})

export default OrderProductDetail