import React, { useContext, useState } from 'react'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import UserAddAddressModal from '../modals/UserAddAddressModal';

const CheckoutAddress = observer(({address, setAddress, deliveryZones}) => {
    const {app} = useContext(Context);

    const [show, setShow] = useState(true);

  return (
    <>
        <div className="checkout-icon">
            <i className='fa-solid fa-location-dot'/>
        </div>
        <div className='checkout-box'>
            <div className="checkout-title">
                <h4>{app.localizationsItems?.delivery_address} <span className='red'>*</span></h4>
                <button className='btn btn-animation btn-sm' onClick={() => setShow(true)}>{app.localizationsItems?.add_address}</button>
            </div>
            {address && <div className="row">
                <div className="col-12">
                    <div className="form-floating mb-4 theme-form-floating">
                        <div className="form-control">{address.full_address}</div>
                        <label>{app.localizationsItems?.address}</label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-floating mb-4 theme-form-floating">
                        <div className="form-control">{address.json_address.apartment}</div>
                        <label>{app.localizationsItems?.apartment}</label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-floating mb-4 theme-form-floating">
                        <div className="form-control">{address.json_address.entrance}</div>
                        <label>{app.localizationsItems?.entrance}</label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-floating mb-4 theme-form-floating">
                        <div className="form-control">{address.json_address.floor}</div>
                        <label>{app.localizationsItems?.floor}</label>
                    </div>
                </div>
                <div className="col-6">
                    <div className="form-floating mb-4 theme-form-floating">
                        <div className="form-control">{address.json_address.note}</div>
                        <label>{app.localizationsItems?.note}</label>
                    </div>
                </div>
            </div>}
        </div>
        {show && <UserAddAddressModal show={show} setShow={setShow} setNewAddress={setAddress} deliveryZones={deliveryZones}/>}
    </>
  )
})

export default CheckoutAddress