import React, { useEffect, useState } from 'react'
import AdminAdvantagesItem from '../parts/AdminAdvantagesItem'
import PageServices from '../../../services/admin/PagesServices';
import { toast } from 'react-toastify';

const AdminAdvantages = ({item, id}) => {
    const [banners, setBanners] = useState();
    const [isPublished, setIsPublished] = useState();

    useEffect(() => {
        if(item){
            setBanners(item.elements)
        }
    }, [item])

    const handleAdd = async() => {
        const temp = {
            title: '',
            image: null
        }
        const newData = {
            parent_id: id,
            type: "advantages",
            value: {
                type: "advantages",
                title: item.title,
                is_background: item.is_background,
                is_component: item.is_component,
                elements: [...banners, temp]
            }
        }

        const res = await PageServices.fields.update(item.id, newData);
        
        if(res.statusCode === 200){
            setBanners([...newData.value.elements])
        }else{
            toast.error(res.message);
        }
    }

    const handleSwitch = (direction) => {
        //console.log(id)
    }

  return (
    <div className={item?.is_background ? "section-t-space footer-section-2 admin-btn-hover" : "section-t-space admin-btn-hover"}>
        <div className="container-fluid-lg">
            <div className="category-item-btns admin-top-margin">
                <div className="category-item-btns-item" onClick={handleAdd}>
                    <i className='fa fa-plus'></i>
                </div>
                <div className="category-item-btns-item">
                    <i className={isPublished ? "icon-visibility" : "icon-visibility_off"}></i>
                </div>
                <div className="category-item-btns-item" onClick={() => handleSwitch('up')}><i className="icon-chevron-down"></i></div>
                <div className="category-item-btns-item" onClick={() => handleSwitch('down')}><i className="icon-chevron-up"></i></div>
                <div className="category-item-btns-item"><i className="icon-delete"></i></div>
            </div>
            <div className="service-section">
                <div className="row g-3">
                    <div className="col-12">
                        <div className="service-contain">
                            {banners?.map((el, index) =>
                                <AdminAdvantagesItem
                                    key={index}
                                    card={el}
                                    item={item}
                                    banners={banners}
                                    setBanners={setBanners}
                                    index={index}
                                    id={id}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminAdvantages