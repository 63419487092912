import React, { useContext, useState } from 'react'
import Slider from "react-slick";
import CatalogServices from '../../../services/general/catalogServices';
import { NavLink } from 'react-router-dom';
import img from "../../../images/placeholder.png"
import { observer } from 'mobx-react-lite';
import { Context } from '../../..';
import { useEffect } from 'react';
import AdminCategoriesSort from '../parts/AdminCategoriesSort';
import DeleteModal from '../modals/DeleteModal';
import PageServices from '../../../services/admin/PagesServices';
import ResizebaleInput from '../ui/ResizebleInput';
import { toast } from 'react-toastify';
import AdminCatalogCategories from './AdminCatalogCategories';
import AdminCatalogServices from '../../../services/admin/AdminCatalogServices';
import CategoryCreateModal from '../modals/CategoryCreateModal';

const AdminCategoriesSlider = observer(({item, id}) => {
    const {app} = useContext(Context);
    const [isPublished, setIsPublished] = useState();

    const [deleteModal, setDeleteModal] = useState();
    const [categories, setCategories] = useState();

    const [createModal, setCreateModal] = useState();

    const settings = {
        arrows: true,
        infinite: false,
        slidesToShow: 8,
        slidesToScroll: 1,
        responsive: [{
                breakpoint: 1745,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 1124,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 692,
                settings: {
                    slidesToShow: 3,
                }
            }, {
                breakpoint: 482,
                settings: {
                    slidesToShow: 2,
                }
            },
        ]
    }

    const fetchCategories = async () => {
        const res = await CatalogServices.categories.index();

        if(res.statusCode === 200){
            setCategories(res.content.items);
        }else{
            toast.error(res.message)
        }
    }

    useEffect(() => {
        fetchCategories();
    }, [])

    const changeTitle = async (name) => {
        const newData = {
            parent_id: id,
            type: "product-categories",
            value: {
                type: "product-categories",
                elements: null,
                title: name
            }
        }

        const res = await PageServices.fields.update(item.id, newData);
        
        if(res.statusCode !== 200){
            toast.error(res.message);
        }
    }

  return (
    categories?.length >= 1 &&
	<section className="category-section-2">
		<div className="container-fluid-lg">
			<div className='category-item-top title'>
                <ResizebaleInput title={item.title} changeTitle={changeTitle} isPublished={isPublished}/>
                <div className="category-item-btns">
                    <div className="category-item-btns-item" onClick={() => setCreateModal(true)}>
                        <i className='fa fa-plus'></i>
                    </div>
                    <div className="category-item-btns-item">
                        <i className={isPublished ? "icon-visibility" : "icon-visibility_off"}></i>
                    </div>
                    <div className="category-item-btns-item"><i className="icon-chevron-down"></i></div>
                    <div className="category-item-btns-item"><i className="icon-chevron-up"></i></div>
                    <div className="category-item-btns-item" onClick={() => setDeleteModal(true)}><i className="icon-delete"></i></div>
                </div>
            </div>
            {item.is_slider ?
                <div className="row">
                    <div className="col-12">
                            <Slider className="category-slider arrow-slider" {...settings}>
                                {categories.map((card, index) =>
                                    <div key={index}>
                                        <div className="shop-category-box border-0 wow fadeIn">
                                            <NavLink to={`/${app.lang}/catalog/${card.slug}`} className="circle-1">
                                                <img src={card.image} className="img-fluid" alt={card.title}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; 
                                                        currentTarget.src = img
                                                    }}
                                                    loading='lazy'
                                                />
                                            </NavLink>
                                            <div className="category-name">
                                                <h6>{card.title}</h6>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Slider>
                    </div>
                </div>
                :
                <AdminCategoriesSort
                    mainCategories={categories}
                    setMainCategories={setCategories}
                    fetchCategories={fetchCategories}
                />
            }
		</div>
        {deleteModal &&
            <DeleteModal
                show={deleteModal}
                setShow={setDeleteModal}
                data={item.id}
                method={PageServices.fields.delete}
                //successCallback={handleDeleteCategories}
            />
        }
        {createModal &&
            <CategoryCreateModal
                show={createModal}
                setShow={setCreateModal}
                categories={categories}
                successCallback={fetchCategories}
            />
        }
	</section>
  )
})

export default AdminCategoriesSlider