import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { Context } from '../../..';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import TextSunEditor from '../ui/TextSunEditor';
import PageServices from '../../../services/admin/PagesServices';

const DescriptionEditModal = observer(({
    show,
    setShow,
    card,
    fetchInfo
}) => {
    const {app} = useContext(Context);

    const handleClose = () => setShow(false);

    const [loading, setLoading] = useState();

    const {register, control, setValue, handleSubmit, formState: {errors}} = useForm();
    
    useEffect(() => {
        if(card){
            setValue('description', card.description);
        }
    }, [card])

    const customSubmit = async (data) => {
        setLoading(true);

        const res = await PageServices.update(card.id, data);

        if(res.statusCode === 200){
            setShow(false);
            fetchInfo();
        }else{
            toast.error(res.message);
        }

        setLoading(false);
    }

  return (
    <Modal show={show} onHide={handleClose} centered size='xl'>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            {loading &&
                <div className='form-loading'>
                    <div className="isLoading"></div>
                </div>
            }
            <form className='row' onSubmit={handleSubmit(customSubmit)}>
                <div className="col-12">
                    <div className="log-in-title mb-3">
                        <h2>{app.localizationsItems?.page_edit}</h2>
                    </div>

                    <Controller
                        name="description"
                        control={control}
                        defaultValue=""
                        render={({field: {onChange, value}}) => (
                            <TextSunEditor
                                onChange={onChange}
                                value={value}
                            />
                        )}>
                    </Controller>

                    <div className="row justify-content-end mt-3">
                        <div className="col-auto">
                            <div onClick={handleClose} className="btn btn-md bg-dark cart-button text-white">{app.localizationsItems?.cancel}</div>
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-md btn-animation">{app.localizationsItems?.save}</button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default DescriptionEditModal