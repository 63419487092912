import React, { useContext } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { Context } from '../../..';
import { useState } from 'react';
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import ProductDescriptionEdit from '../modals/ProductDescriptionEdit';

const AdminProductNav = observer(({item, fetchInfo}) => {
    const [tabs, setTabs] = useState();

    const {app} = useContext(Context);

    const [showEdit, setShowEdit] = useState();

    useEffect(() => {
        if(item){
            setTabs(item.elements?.find(it => it?.type === "tab-component")?.elements);
        }
    }, [item])

  return (
	<section>
		<div className="container-fluid-lg mb-5">
			<div className="row">
                {tabs ?
                    <div className="col-12">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="tab-description0">
                            <div className='product-section-box m-0'>
                                <Nav className="nav-tabs custom-nav">
                                    {tabs?.map((card, index) =>
                                        <Nav.Item key={index}>
                                            <Nav.Link eventKey={`tab-description${index}`}>{card.title}</Nav.Link>
                                        </Nav.Item>
                                    )}
                                </Nav>

                                <Tab.Content className="tab-content custom-tab">
                                    {tabs?.map((card, index) =>
                                        <Tab.Pane className="tab-pane" eventKey={`tab-description${index}`} key={index}>
                                            <div className="product-description" dangerouslySetInnerHTML={{ __html: card.description}}>
                                            </div>
                                        </Tab.Pane>
                                    )}
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                    :
                    <div className="col-12">
                        <div className="product-section-box">
                            <div className='product-section-box m-0 admin-banner-hover'>
                                <ul className="nav-tabs custom-nav">
                                    <li className="nav-item" role="presentation">
										<button className="nav-link active" type="button" >{app.localizationsItems?.description}</button>
									</li>
                                </ul>

                                <div className="tab-content custom-tab">
                                    <div className="tab-pane show active">
                                        <div className="product-description" dangerouslySetInnerHTML={{ __html: item.description}}></div>
                                    </div>
                                </div>
                                <div className='admin-banner-item-content'>
                                    <div className='admin-banner-btn edit' onClick={() => setShowEdit(true)}>{app.localizationsItems?.edit}
                                        <i className='icon-pen'></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
			</div>
		</div>
        {showEdit &&
            <ProductDescriptionEdit
                show={showEdit}
                setShow={setShowEdit}
                card={item}
                fetchInfo={fetchInfo}
            />
        }
	</section>
  )
})

export default AdminProductNav