import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite'

const DeleteModal = observer(({
    show,
    setShow,
    method,
    data,
    successCallback
}) => {
    const {app} = useContext(Context);
    const handleClose = () => setShow(false);

    const handleDelete = async () => {
        const res = await method(data);

        if(res.statusCode === 200){
            toast.success(res.message);
            handleClose();
            successCallback();
        }else{
            toast.error(res.message)
        }
    }

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
            <h2>{app.localizationsItems?.delete_item}</h2>
        </Modal.Header>
        <Modal.Body>
            <p>{app.localizationsItems?.deleted_forever}</p>
            <div className="row justify-content-end mt-3">
                <div className="col-auto">
                    <div onClick={handleClose} className="btn btn-md bg-dark cart-button text-white">{app.localizationsItems?.cancel}</div>
                </div>
                <div className="col-auto">
                    <div onClick={handleDelete} className="btn btn-md btn-animation">{app.localizationsItems?.delete}</div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default DeleteModal