import React, { useContext } from 'react'
import img1 from "../../svg/grid-3.svg"
import img2 from "../../svg/grid-4.svg"
import img3 from "../../svg/grid.svg"
import img4 from "../../svg/list.svg"
import { Context } from '../..'
import { observer } from 'mobx-react-lite'

const FilterPageTop = observer(({layout, setLayout, setOpenFilter}) => {
    const {app} = useContext(Context);
  return (
    <div className="show-button">
        <div className="filter-button-group">
            <div className="filter-button d-inline-block d-lg-none" onClick={() => setOpenFilter(true)}>
                <i className="fa-solid fa-filter"></i>{app.localizationsItems?.filter_text}
            </div>
        </div>

        <div className="top-filter-menu">
            <div className="grid-option">
                <ul>
                    <li className={layout === "tree-column" ? "three-grid d-xxl-inline-block d-none active" : "three-grid d-xxl-inline-block d-none"}>
                        <div className='but-block' onClick={() => setLayout('tree-column')}>
                            <img src={img1}  alt="" loading='lazy'/>
                        </div>
                    </li>
                    <li className={layout === "four-column" ? "grid-btn active" : "grid-btn"}>
                        <div className='but-block' onClick={() => setLayout('four-column')}>
                            <img src={img2} className="d-lg-inline-block d-none" alt="" loading='lazy'/>
                            <img src={img3} className="img-fluid d-lg-none d-inline-block" alt="" loading='lazy'/>
                        </div>
                    </li>
                    <li className={layout === "list" ? "list-btn active" : "list-btn"}>
                        <div className='but-block' onClick={() => setLayout('list')}>
                            <img src={img4} alt="" loading='lazy'/>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  )
})

export default FilterPageTop