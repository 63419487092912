import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import img from "../../../images/placeholder.png"
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../../..';
import AdvertisingBannerEditModal from '../modals/AdvertisingBannerEditModal';
import DeleteModal from '../modals/DeleteModal';
import PageServices from '../../../services/admin/PagesServices';

const AdminBannerSection2 = observer(({item, id}) => {
    const {app} = useContext(Context);

	const [banners, setBanners] = useState();
    const [showEdit, setShowEdit] = useState();
    const [deleteModal, setDeleteModal] = useState();
    const [isPublished, setIsPublished] = useState();

    const navigate = useNavigate();

	useEffect(() => {
		if(item){
			setBanners(item.elements)
		}
	}, [item])

    const handleDelete = () => {
        setBanners(null)
    }

  return (  
	banners &&
	<section className="banner-section section-b-space admin-banner-hover">
		<div className="container-fluid-lg">
            <div className="category-item-btns">
                <div className="category-item-btns-item">
                    <i className={isPublished ? "icon-visibility" : "icon-visibility_off"}></i>
                </div>
                <div className="category-item-btns-item"><i className="icon-chevron-down"></i></div>
                <div className="category-item-btns-item"><i className="icon-chevron-up"></i></div>
                <div className="category-item-btns-item" onClick={() => setDeleteModal(true)}><i className="icon-delete"></i></div>
            </div>
			<div className="row">
				<div className="col-12">
					<div className="banner-contain-3 section-b-space section-t-space">
						<img src={'https://shop-003-003.panama.kz'+banners[0].image} className="img-fluid bg-img position-absolute" alt=""
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img
                            }}
                            loading='lazy'
                        />
						<div className="banner-detail p-center text-dark position-relative text-center p-0">
							<div style={{color: banners[0].banner_font_color}}>
								<h4 className="ls-expanded text-uppercase theme-color" dangerouslySetInnerHTML={{__html: banners[0].subtitle_top}}></h4>
								<h2 className="my-3">{banners[0].title}</h2>
								<h4 className="fw-300">{banners[0].description}</h4>
								{banners[0].link && <button onClick={() => navigate(banners[0].link)} className="btn theme-bg-color mt-sm-4 btn-md mx-auto text-white fw-bold">{banners[0].subtitle}</button>}
							</div>
						</div>
                        <div className='admin-banner-item-content'>
                                <div className='admin-banner-btn edit' onClick={() => setShowEdit(true)}>{app.localizationsItems?.edit}
                                    <i className='icon-pen'></i>
                                </div>
                                <div className='admin-banner-btn delete' onClick={() => setDeleteModal(true)}>{app.localizationsItems?.delete}
                                    <i className='icon-delete'></i>
                                </div>
                            </div>
					</div>
				</div>
			</div>
		</div>
        {showEdit &&
            <AdvertisingBannerEditModal
                show={showEdit}
                setShow={setShowEdit}
                banners={banners}
                setBanners={setBanners}
                index={0}
                item={item}
                id={id}
            />
        }
        {deleteModal &&
            <DeleteModal
                show={deleteModal}
                setShow={setDeleteModal}
                data={item.id}
                method={PageServices.fields.delete}
                successCallback={handleDelete}
            />
        }
	</section>
  )
})

export default AdminBannerSection2