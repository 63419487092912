import React, { useContext, useState } from 'react'
import img from "../../../images/placeholder.png"
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../../..';
import CategoryEditModal from '../modals/CategoryEditModal';
import AdminCatalogServices from '../../../services/admin/AdminCatalogServices';
import DeleteModal from '../modals/DeleteModal';
import { toast } from 'react-toastify';
import CategoryCreateModal from '../modals/CategoryCreateModal';

const AdminCategoryItem2 = observer(({
    card,
    categories,
    fetchCategories,
    index
}) => {
    const {app} = useContext(Context);

    const [showEdit, setSHowEdit] = useState();
    const [deleteModal, setDeleteModal] = useState();
    const [createModal, setCreateModal] = useState();

    const [isPublished, setIsPublished] = useState(card.is_published);

    const [data, setData] = useState();

    const changePublished = () => {

    }

    const createCategory = (direction) => {
        let newData = {after: card.id}

        if(direction){
            if(index-1 > 0){
                newData = {after: categories[index-1].id}
            }else{
                newData = {parent_id: card.parent?.id}
            }
        }
        setData(newData);
        setCreateModal(true)
    }

  return (
    <>
        <div className='admin-banner-hover'>
            <div className='product-add-btn' onClick={() => createCategory('before')}>
                <i className='fa fa-plus'></i>
            </div>
            <NavLink to={`/${app.lang}/catalog/${card.slug}`} className="category-box">
                <div>
                    <img src={card.image ? 'https://shop-003-003.panama.kz'+card.image : img} alt={card.title}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img
                        }}
                        loading='lazy'
                    />
                    <h5>{card.title}</h5>
                </div>
            </NavLink>
            <div className='admin-banner-item-content admin-content-mini admin-category-grid'>
                <div className='admin-banner-btn change' onClick={changePublished}>
                    <i className={isPublished ? 'icon-visibility' : 'icon-visibility_off'}></i>
                </div>
                <div className='admin-banner-btn change'>
                    <NavLink to={`/${app.lang}/catalog/${card.slug}`}>
                        <i className='fa fa-share'></i>
                    </NavLink>
                </div>
                <div className='admin-banner-btn edit' onClick={() => setSHowEdit(true)}>
                    <i className='icon-pen'></i>
                </div>
                <div className='admin-banner-btn delete' onClick={() => setDeleteModal(true)}>
                    <i className='icon-delete'></i>
                </div>
            </div>
            <div className='product-add-btn right' onClick={() => createCategory()}>
                <i className='fa fa-plus'></i>
            </div>
        </div>
        {deleteModal &&
            <DeleteModal
                show={deleteModal}
                setShow={setDeleteModal}
                data={card.id}
                method={AdminCatalogServices.categories.delete}
                successCallback={fetchCategories}
            />
        }
        {showEdit && 
            <CategoryEditModal
                show={showEdit}
                setShow={setSHowEdit}
                categories={categories}
                card={card}
                successCallback={fetchCategories}
            />
        }
        {createModal &&
            <CategoryCreateModal
                show={createModal}
                setShow={setCreateModal}
                categories={categories}
                successCallback={fetchCategories}
            />
        }
    </>
  )
})

export default AdminCategoryItem2