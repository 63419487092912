import React, { useContext, useEffect, useState } from 'react'
import Slider from "react-slick";
import PageServices from '../../../services/admin/PagesServices';
import { toast } from 'react-toastify';
import ResizebaleInput from '../ui/ResizebleInput';
import DeleteModal from '../modals/DeleteModal';
import AdminDealsItem from '../parts/AdminDealsItem';
import { observer } from 'mobx-react-lite';
import { Context } from '../../..';

const AdminBestDeals = observer(({elem, id}) => {
    const {app} = useContext(Context);
    
    const [slidesToShow, setSlidesToShow] = useState(3)

    const [isPublished, setIsPublished] = useState();
    const [deleteModal, setDeleteModal] = useState();

    const [banners, setBanners] = useState();

    useEffect(() => {
        if(elem.elements){
            setBanners(elem.elements)
        }
    }, [elem])

    useEffect(() => {
        if(app.mobView){
            setSlidesToShow(1)
        }else{
            setSlidesToShow(3)
        }
    }, [app.mobView]);

    const changeTitle = async (name) => {
        const newData = {
            parent_id: id,
            type: "opening-slider",
            value: {
                type: "opening-slider",
                elements: elem.elements,
                title: name
            }
        }

        const res = await PageServices.fields.update(elem.id, newData);
        
        if(res.statusCode !== 200){
            toast.error(res.message);
        }
    }

    const handleAdd = async () => {
        const tempItem = {
            button_text: null,
            description: null,
            front_image: null,
            link: null,
        }

        const newData = {
            parent_id: id,
            type: "opening-slider",
            value: {
                type: "opening-slider",
                title: elem.title,
                elements: [...banners, tempItem]
            }
        }

        const res = await PageServices.fields.update(elem.id, newData);
        
        if(res.statusCode === 200){
            setBanners([...banners, tempItem])
        }else{
            toast.error(res.message);
        }
    }

  return (
	<section>
		<div className="container-fluid-lg">
            <div className='category-item-top title'>
                <ResizebaleInput title={elem.title} changeTitle={changeTitle} isPublished={isPublished}/>
                <div className="category-item-btns">
                    <div className="category-item-btns-item">
                        <i className={isPublished ? "icon-visibility" : "icon-visibility_off"}></i>
                    </div>
                    <div className="category-item-btns-item"><i className="icon-chevron-down"></i></div>
                    <div className="category-item-btns-item"><i className="icon-chevron-up"></i></div>
                    <div className="category-item-btns-item" onClick={() => setDeleteModal(true)}><i className="icon-delete"></i></div>
                </div>
            </div>
			<div className="row">
				<div className="col-12">
					{banners && 
                        <Slider className="three-slider arrow-slider ratio_65"
                            arrows={true}
                            infinite={false}
                            slidesToShow={slidesToShow}
                            slidesToScroll={1}
                            responsive={[{
                                    breakpoint: 1300,
                                    settings: {
                                        slidesToShow: 2,
                                    }
                                },
                                {
                                    breakpoint: 757,
                                    settings: {
                                        slidesToShow: 1,
                                        fade: true,
                                    }
                                },
                            ]}
                        >
                            {banners?.map((card, index) =>
                                <AdminDealsItem
                                    card={card}
                                    key={index}
                                    elem={elem}
                                    banners={banners}
                                    setBanners={setBanners}
                                    index={index}
                                    id={id}
                                />
                            )}
                            <div className='item-banner-add'>
                                <div className='item-banner-add-content' onClick={handleAdd}>
                                    {app.localizationsItems?.add}
                                    <i className='fa fa-plus'></i>
                                </div>
                            </div>
                        </Slider>
                    }
				</div>
			</div>
		</div>
        {deleteModal &&
            <DeleteModal
                show={deleteModal}
                setShow={setDeleteModal}
                data={elem.id}
                method={PageServices.fields.delete}
                //successCallback={handleDeleteCategories}
            />
        }
	</section>
  )
})

export default AdminBestDeals