import React, { useContext } from 'react'
import CartIcon from './CartIcon'
import Menu from './Menu'
import StickyMenu from './StickyMenu'
import img1 from "../../images/icon/hot-sale.png"
import { NavLink } from 'react-router-dom'
import Search from './Search'
import { Dropdown } from 'react-bootstrap'
import MobileFixMenu from '../simple/MobileFixMenu'
import UserIcon from './UserIcon'
import FavoritesIcon from './FavoritesIcon'
import AppServices from '../../services/general/appServices'
import { useState } from 'react'
import MenuMobile from './MenuMobile'
import { useEffect } from 'react'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'
import ApplicationModal from '../modals/ApplicationModal'
import SelectCity from '../simple/SelectCity'
import GoogleTranslateWidget from '../simple/GoogleTranslateWidget'
import NotificationTop from '../simple/NotificationTop'

const Header = observer(() => {
    const {app} = useContext(Context)

    const [items, setItems] = useState();

    const [phone, setPhone] = useState();
	const [show, setShow] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [logo, setLogo] = useState();
    const [sticky, setStiky] = useState();
    const [phoneEnabled, setPhoneEnabled] = useState(false);
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [searchEnabled, setSearchnEnabled] = useState(false);

    const fetchMenu = async () => {
        const res = await AppServices.menu('main-menu');

        if(res.statusCode === 200){
            setItems(res.content)
        }
    }
    useEffect(()=> {
        fetchMenu()
    }, [app.lang])

    useEffect(() => {
        if(app.settings.length >= 1){
            setPhone(app.settings.find(it => it.type === "phone")?.value);
            setLogo(app.settings.find(it => it.type === "logo")?.value);
            setPhoneEnabled(app.settings.find(it => it.type === "phone-enabled")?.value);
            setButtonEnabled(app.settings.find(it => it.type === "button-enabled")?.value);
            setSearchnEnabled(app.settings.find(it => it.type === "search-enabled")?.value);
        }
    }, [app.settings])

	const handleShow = () => setShow(true);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if(scrolled > 120){
            setStiky(true)
        }else{
            setStiky(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible)
    }, [])

    const handleChange = (lang) => {
        if(app.lang !== lang.slug){
            app.setLang(lang.slug)
        }
    }

  return (
	<>
        <NotificationTop/>
		<header className={sticky ? "header-2 active" : "header-2"}>
			<div className="top-nav top-header sticky-header sticky-header-3">
				<div className="container-fluid-lg">
					<div className="row">
						<div className="col-12">
							<div className="navbar-top">
								<button className="navbar-toggler d-xl-none d-block p-0 me-3" type="button" onClick={handleShow}>
									<span className="navbar-toggler-icon">
										<i className="iconly-Category icli theme-color"></i>
									</span>
								</button>
								<NavLink to={`/${app.lang}`} className="web-logo nav-logo">
									<img src={logo} className="img-fluid" alt="logo" loading='lazy'/>
								</NavLink>

								{searchEnabled && <Search/>}

								<div className="rightside-menu">
                                    <SelectCity/>
									{app.languages?.length > 1 && <div className="dropdown-dollar">
										<Dropdown id="dropdown-basic">
											<Dropdown.Toggle>
												<span className='lang-color'>{app.lang}</span> <i className="fa-solid fa-angle-down"></i>
											</Dropdown.Toggle>
											<Dropdown.Menu >
												{app.languages.map((lang, index) =>
													<Dropdown.Item key={index} onClick={() => handleChange(lang)}>{lang.title}</Dropdown.Item>
												)}
											</Dropdown.Menu>
										</Dropdown>
									</div>}

									<div className="option-list">
										<ul>
                                            {app.userCabinet && <FavoritesIcon/>}
											<CartIcon/>
											{app.userCabinet && <UserIcon/>}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container-fluid-lg menu-color">
				<div className="row">
					<div className="col-12">
						<div className="main-nav">
							<StickyMenu item={items}/>
							<Menu item={items}/>
							<MenuMobile item={items} show={show} setShow={setShow}/>

							<div className="right-nav">
								{phoneEnabled && <div className="nav-number">
                                    <svg width="32" height="32" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.03547 6.09374C0.863114 6.40832 0 7.47836 0 8.75V11.75C0 13.2688 1.23122 14.5 2.75 14.5H5.25C5.66421 14.5 6 14.1642 6 13.75V6.75C6 6.33579 5.66421 6 5.25 6H3.56483C3.99399 3.53002 6.50862 1.5 9.75 1.5C12.9914 1.5 15.506 3.53002 15.9352 6H14.25C13.8358 6 13.5 6.33579 13.5 6.75V13.75C13.5 14.1642 13.8358 14.5 14.25 14.5H15.913C15.5744 15.9334 14.2868 17 12.75 17H11.6046C11.3079 16.267 10.5893 15.75 9.75 15.75C8.64543 15.75 7.75 16.6454 7.75 17.75C7.75 18.8546 8.64543 19.75 9.75 19.75C10.5893 19.75 11.3079 19.233 11.6046 18.5H12.75C15.1498 18.5 17.1339 16.7204 17.4547 14.4089C18.632 14.0977 19.5 13.0252 19.5 11.75V8.75C19.5 7.47836 18.6369 6.40832 17.4645 6.09375C17.0831 2.58959 13.6762 0 9.75 0C5.8238 0 2.41693 2.58959 2.03547 6.09374Z" fill="black"/>
                                    </svg>
                                    {phone?.map((ph, index) =>
                                        <span key={index}><a href={`tel:${ph}`}>{ph}</a></span>
                                    )}
								</div>}
								{buttonEnabled && <button className="btn theme-bg-color ms-3 fire-button"
                                    onClick={() => setModalOpen(true)}
                                >
									<div className="fire">
										<img src={img1} className="img-fluid" alt="" loading='lazy'/>
									</div>
									<span>{app.localizationsItems?.hot_line}</span>
								</button>}
							</div>
						</div>
					</div>
				</div>
			</div>
            {modalOpen && <ApplicationModal show={modalOpen} setShow={setModalOpen}/>}
		</header>
		<MobileFixMenu/>
	</>
  )
})

export default Header