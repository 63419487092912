import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import { Context } from '../../..';

const Slug = observer(({title, setTitle, slug, setSlug}) => {
    const {app} = useContext(Context);

    const generate_url = (str) => {
        var url = str.replace(/[\s]+/gi, '-');
        url = url.replace(/\-+/g, '-');
        url = translit(url);
        url = url.replace(/[^0-9a-z_\-]+/gi, '').toLowerCase();	
        return url;
    }

    const translit = (str) => {
        var ru=("А-а-Б-б-В-в-Ґ-ґ-Г-г-Д-д-Е-е-Ё-ё-Є-є-Ж-ж-З-з-И-и-І-і-Ї-ї-Й-й-К-к-Л-л-М-м-Н-н-О-о-П-п-Р-р-С-с-Т-т-У-у-Ф-ф-Х-х-Ц-ц-Ч-ч-Ш-ш-Щ-щ-Ъ-ъ-Ы-ы-Ь-ь-Э-э-Ю-ю-Я-я").split("-")   
        var en=("A-a-B-b-V-v-G-g-G-g-D-d-E-e-E-e-E-e-ZH-zh-Z-z-I-i-I-i-I-i-J-j-K-k-L-l-M-m-N-n-O-o-P-p-R-r-S-s-T-t-U-u-F-f-H-h-TS-ts-CH-ch-SH-sh-SCH-sch-'-'-Y-y-'-'-E-e-YU-yu-YA-ya").split("-")   
            var res = '';
        for(var i=0, l=str.length; i<l; i++)
        { 
            var s = str.charAt(i), n = ru.indexOf(s); 
            if(n >= 0) { res += en[n]; } 
            else { res += s; } 
        } 
        return res;  
    }

    const generateSlug = (e) => {
        setTitle(e.target.value);
        setSlug(generate_url(e.target.value))
    }


  return (
    <>
        <div className="form-floating theme-form-floating mt-3">
            <input type="text" className="form-control" placeholder={app.localizationsItems?.title}
                value={title} 
                onChange={e => generateSlug(e)}
                required
            />
            <label>{app.localizationsItems?.title}</label>
        </div>
        <div className="form-floating theme-form-floating mt-3">
            <input type="text" className="form-control" placeholder={'Slug'}
                value={slug}
                onChange={e => setSlug(e.target.value)}
                required
            />
            <label>{'Slug'}</label>
        </div>
    </>
  )
})

export default Slug