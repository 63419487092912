import React, { useState } from 'react'
import img from "../../../images/placeholder.png"
import DealsDeleteModal from '../modals/DealsDeleteModal';
import PageServices from '../../../services/admin/PagesServices';
import { toast } from 'react-toastify';
import AdvantagesEditModal from '../modals/AdvantagesEditModal';

const AdminAdvantagesItem = ({
    card,
    item,
    banners,
    setBanners,
    index,
    id
}) => {
    const [deleteModal, setDeleteModal] = useState();
    const [showEdit, setShowEdit] = useState();

    const handleDelete = async () => {
        const newArray = banners.filter((el, ind) => ind !== index);

        const newData = {
            parent_id: id,
            type: item.type,
            value: {
                type: item.type,
                title: item.title,
                is_background: item.is_background,
                elements: [
                    ...newArray
                ]
            }
        }

        const res = await PageServices.fields.update(item.id, newData);
        
        if(res.statusCode === 200){
            setBanners(newArray);
            setDeleteModal(false);
        }else{
            toast.error(res.message);
        }
    }

  return (
    <>
        <div className="service-box admin-banner-hover">
            <div className="service-image">
                <img src={'https://shop-003-003.panama.kz'+card.image}alt=""
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img
                    }}
                    loading='lazy'
                />
            </div>

            <div className="service-detail">
                <h5>{card.title}</h5>
            </div>
            <div className='admin-banner-item-content admin-content-mini'>
                <div className='admin-banner-btn edit' onClick={() => setShowEdit(true)}>
                    <i className='icon-pen'></i>
                </div>
                <div className='admin-banner-btn delete' onClick={() => setDeleteModal(true)}>
                    <i className='icon-delete'></i>
                </div>
            </div>
        </div>
        {deleteModal &&
            <DealsDeleteModal
                show={deleteModal}
                setShow={setDeleteModal}
                successCallback={handleDelete}
            />
        }
        {showEdit &&
            <AdvantagesEditModal
                show={showEdit}
                setShow={setShowEdit}
                card={card}
                banners={banners}
                setBanners={setBanners}
                index={index}
                id={id}
                item={item}
            />
        }
    </>
  )
}

export default AdminAdvantagesItem