import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import img1 from "../../../images/inner-page/log-in.png"
import AuthServices from '../../../services/general/authServices';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import ReactInputMask from 'react-input-mask';
import PhoneCheckModal from '../../../components/modals/PhoneCheckModal';

const LogInContainer = observer(() => {
	const {handleSubmit, register, control, formState: {errors}} = useForm();
	const navigate = useNavigate();
    const [loading, setLoading] = useState();
    const [phoneCheckModal, setPhoneCheckModal] = useState();
    const [phone, setPhone] = useState();
    const [showPassword, setShowPassword] = useState();

    const {app, user} = useContext(Context);
	
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };

	const CustomSubmit = async (data) => {
        setLoading(true);
        const res = await AuthServices.logIn(data);

        if (res.statusCode === 200) {
            if(app.registerByPhone){
                setPhoneCheckModal(true);
                setPhone(data.phone);
            }else{
                user.setUser(res.content);
                user.setToken(res.content.token);
                user.setBasketChange(Math.random().toString(16));
                toast.success(res.message);
                navigate(`/${app.lang}`);
            }
        }else{
            toast.error(res.message);
        }
        setLoading(false);
    }

  return (
	<section className="log-in-section background-image-2 section-b-space">
		<div className="container-fluid-lg w-100">
			<div className="row">
				<div className="col-xxl-6 col-xl-5 col-lg-6 d-lg-block d-none ms-auto">
					<div className="image-contain">
						<img src={img1} className="img-fluid" alt="" loading='lazy'/>
					</div>
				</div>

				<div className="col-xxl-4 col-xl-5 col-lg-6 col-sm-8 mx-auto">
					<div className="log-in-box">
						<div className="log-in-title">
							<h3>{app.localizationsItems?.login_title}</h3>
							<h4>{app.localizationsItems?.login_content}</h4>
						</div>

						<div className="input-box">
							<form className={loading ? "row g-4 isLoading" : "row g-4"} onSubmit={handleSubmit(CustomSubmit)}>
								{app.registerByPhone ?
                                    <div className="col-12">
                                        <div className="form-floating theme-form-floating log-in-form">
                                            <Controller defaultValue="" name="phone" control={control}
                                                render={({field: {onChange, value}}) => (
                                                <ReactInputMask onChange={onChange} value={value}
                                                                    mask="+7(999)999-99-99" maskChar="_">
                                                                    {
                                                                        inputProps => (
                                                                            <input type="tel" required $error={errors.phone} className="form-control" placeholder={app.localizationsItems?.phone} {...inputProps}/>
                                                                        )
                                                                    }
                                                </ReactInputMask>
                                                        )}
                                                rules={{pattern: {
                                                    value: /(?:\+|\d)[\d\-() ]{9,}\d/g
                                                }}}
                                            />
                                            <label>{app.localizationsItems?.phone}*</label>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div className="col-12">
                                            <div className="form-floating theme-form-floating log-in-form">
                                                <input {...register('email')} required type="email" className="form-control" placeholder={app.localizationsItems?.email}/>
                                                <label>{app.localizationsItems?.email}*</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating theme-form-floating log-in-form">
                                                <input {...register('password')} required type={showPassword ? "text" : "password"} className="form-control" id="password" placeholder={app.localizationsItems?.password}/>
                                                <label>{app.localizationsItems?.password}*</label>
                                                <span className='password-eye' onClick={togglePasswordVisibility}><i className={showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}></i></span>
                                            </div>
                                        </div>
                                    </>
                                }
								{!app.registerByPhone && <div className="col-12">
									<div className="forgot-box">
										<div className="form-check ps-0 m-0 remember-box"></div>
										<NavLink to={`/${app.lang}/forgot-password`} className="forgot-password">{app.localizationsItems?.forgot_password}</NavLink>
									</div>
								</div>}
								<div className="col-12">
									<button className="btn btn-animation w-100 justify-content-center" type="submit">{app.localizationsItems?.login}</button>
								</div>
							</form>
						</div>

						<div className="other-log-in">
							<h6></h6>
						</div>

						{app.registerByPhone ? 
                            <></>
                            :
                            <div className="sign-up-box">
                                <h4>{app.localizationsItems?.not_account}</h4>
                                <NavLink to={`/${app.lang}/registration`}>{app.localizationsItems?.regis}</NavLink>
                            </div>
                        }
					</div>
				</div>
			</div>
		</div>
        {phoneCheckModal && <PhoneCheckModal show={phoneCheckModal} setShow={setPhoneCheckModal} phone={phone} />}
	</section>
  )
})

export default LogInContainer