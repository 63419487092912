import React, { useContext, useState, useEffect } from 'react'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import { useLocation, useNavigate } from 'react-router-dom'
import BannerTop from '../../simple/BannerTop'
import FilterPageTop from '../../complex/FilterPageTop'
import Pagination from '../../complex/Pagination'
import Filter from '../../complex/Filter'
import CatalogServices from '../../../services/general/catalogServices'
import AdminProductCard2 from '../parts/AdminProductCard2'
import AdminCatalogCategories from './AdminCatalogCategories'

const AdminProductContainer = observer(({item, slug}) => {
    const {app, user} = useContext(Context);

    const navigate = useNavigate();

    const [layout, setLayout] = useState('four-column');
    const [openFilter, setOpenFilter] = useState();

    let query = new URLSearchParams(useLocation().search);
    const [pageCount, setPageCount] = useState();
    const [params, setParams] = useState(query);
    const [loading, setLoading] = useState();
    const [items, setItems] = useState();

    const fetchProducts = async () => {
        setLoading(true);
        let str = user.wishlistUid ? `favorite_uid=${user.wishlistUid}&` : '';
        let str2 = query.toString();
        let str3 = query.get('categories[]') ? `` : slug ? `categories[]=${slug}&` : ``;
        const res = await CatalogServices.products.index(`${str3}${str}${str2}`);

        if(res.statusCode === 200){
            setItems(res.content);
            setPageCount(res.content?.total_pages);
        }
        setLoading(false);
    }

    useEffect(() => {
        if(!query.get('page')){
            params.set('page', 1);
        }
        fetchProducts()
    }, [params, query.get('categories[]'), app.cityString])

    const handlePageClick = (data) => {
        query.set('page', data.selected + 1);
        setParams(query);
        
        navigate({
            search: query.toString()
        });
    };

    const setFilterData = (filterString) => {
        let newParams = new URLSearchParams(filterString);
        if (query.get('order')) {
            newParams.set('order', query.get('order'))
        }
        setParams(newParams);
    };

    const [categories, setCategories] = useState();

    const fetachcategories = async () => {
        const res = slug ? await CatalogServices.categories.view(slug) : await CatalogServices.categories.index();
        if(res?.statusCode === 200){
            if(slug){
                res.content.children && setCategories(res.content.children);
            }else{
                setCategories(res.content.items);
            }
        }
    }

    useEffect(() => {
        fetachcategories();
    }, [slug])

  return (
	<section className="section-b-space shop-section">
		<div className="container-fluid-lg">
			<div className="row justify-content-center">
                <div className="col-12">
                    <div className="back-button2" onClick={() => navigate(-1)}>
                        <span><i className="fa-solid fa-arrow-left"></i>{app.localizationsItems?.back}</span>
                    </div>
                </div>
                <Filter setFilterData={setFilterData} openFilter={openFilter} setOpenFilter={setOpenFilter} slug={slug} categories={categories}/>
				<div className="col-custome-9">
                    {item?.detail_image && <BannerTop card={item}/>}
                    <AdminCatalogCategories categories={categories} setCategories={setCategories} fetchCategories={fetachcategories}/>
                    <FilterPageTop layout={layout} setLayout={setLayout} setOpenFilter={setOpenFilter}/>
					<div className={layout === "tree-column" ?
                                     "row g-sm-4 g-3 product-list-section row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2" :
                                     layout === "list" ?
                                     "row g-sm-4 g-3 product-list-section row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2 list-style"
                                     :
                                     "row g-sm-4 g-3 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-3 row-cols-2 product-list-section"}
                        >
                        {items?.items?.map((card, index) =>
                            <AdminProductCard2 
                                key={card.id} 
                                item={card} 
                                fetchItems={fetchProducts}
                            />
                        )}
					</div>
                    
                    <div className='custome-pagination'>
                        <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                    </div>
				</div>
			</div>
		</div>
	</section>
  )
})

export default AdminProductContainer