import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import img from "../../../images/placeholder.png"
import { Context } from '../../..';
import ArticleEditModal from '../modals/ArticleEditModal';
import DeleteModal from '../modals/DeleteModal';
import AdminArticlesServices from '../../../services/admin/AdminArticlesServices';

const AdminBlogCard = ({card, fetchItems}) => {
    const {app} = useContext(Context);

    const [isPublished, setIsPublished] = useState();

    const [showEdit, setShowEdit] = useState();
    const [deleteModal, setDeleteModal] = useState();

  return (
    <>
        <div className="blog-box ratio_50 admin-banner-hover">
            <NavLink to={`/${app.lang}/articles/${card.slug}`} className="blog-box-image">
                <img src={card.image ? 'https://shop-003-003.panama.kz'+card.image : img} className="img-fluid bg-img" alt=""
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img
                    }}
                    loading='lazy'
                />
            </NavLink>

            <div className="blog-detail">
                {card.subtitle && <label>{card.subtitle}</label>}
                <NavLink to={`/${app.lang}/articles/${card.slug}`}>
                    <h2>{card.title}</h2>
                </NavLink>
                <div className="blog-list">
                    <span>{card.date}</span>
                </div>
            </div>
            <div className='admin-banner-item-content'>
                <div className='admin-banner-btn change'>{app.localizationsItems?.published}
                    <i className={isPublished ? 'icon-visibility' : 'icon-visibility_off'}></i>
                </div>
                <div className='admin-banner-btn edit' onClick={() => setShowEdit(true)}>{app.localizationsItems?.edit}
                    <i className='icon-pen'></i>
                </div>
                <div className='admin-banner-btn delete' onClick={() => setDeleteModal(true)}>{app.localizationsItems?.delete}
                    <i className='icon-delete'></i>
                </div>
            </div>
        </div>
        {showEdit && 
            <ArticleEditModal
                show={showEdit}
                setShow={setShowEdit}
                card={card}
                fetchItems={fetchItems}
            />
        }
        {deleteModal &&
            <DeleteModal
                show={deleteModal}
                setShow={setDeleteModal}
                data={card.id}
                method={AdminArticlesServices.delete}
                successCallback={fetchItems}
            />
        }
    </>
  )
}

export default AdminBlogCard