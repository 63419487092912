import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { Context } from '../../..';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import UploadImg from '../ui/UploadImg';
import CustomSelect from '../../ui/CustomSelect';
import AdminCatalogServices from '../../../services/admin/AdminCatalogServices';
import Slug from '../ui/Slug';

const CategoryCreateModal = observer(({
    show,
    setShow,
    categories,
    successCallback
}) => {
    const {app} = useContext(Context);

    const handleClose = () => setShow(false);

    const [loading, setLoading] = useState();

    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');

    const {register, control, setValue, handleSubmit, formState: {errors}} = useForm();

    const customSubmit = async (data) => {
        setLoading(true);

        const newData = {
            title: title,
            slug: slug,
            ...data
        }

        const res = await AdminCatalogServices.categories.store(newData);

        if(res.statusCode === 200){
            successCallback();
            setShow(false);
        }else{
            toast.error(res.message);
        }
        setLoading(false);
    }

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            {loading &&
                <div className='form-loading'>
                    <div className="isLoading"></div>
                </div>
            }
            <form className='row' onSubmit={handleSubmit(customSubmit)}>
                <div className="col-12">
                    <div className="log-in-title mb-3">
                        <h2>{app.localizationsItems?.category_edit}</h2>
                    </div>
                    <Controller
                        control={control}
                        name="image"
                        render={({field: {onChange, value}}) => (
                            <UploadImg onChange={onChange} value={value} $error={errors.image}/>
                        )}
                    />
                    <Slug title={title} setTitle={setTitle} slug={slug} setSlug={setSlug}/>
                    <div className='mt-3'>
                        {categories && 
                            <Controller name="parent_id" control={control}
                                render={({field: {onChange, value}}) => (
                                    <CustomSelect options={categories} 
                                        onChange={onChange} 
                                        placeholder={app.localizationsItems?.parent_category}
                                        value={value}
                                    />
                                )}
                            />
                        }
                    </div>
                    <div className='d-flex justify-content-between align-items-center mt-3'>
                        {app.localizationsItems?.published}
                        <div className='admin-ckheckout'>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" {...register('is_published')}/>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-end mt-3">
                        <div className="col-auto">
                            <div onClick={handleClose} className="btn btn-md bg-dark cart-button text-white">{app.localizationsItems?.cancel}</div>
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-md btn-animation">{app.localizationsItems?.save}</button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default CategoryCreateModal