import React from 'react'
import AdminCategoryItem2 from '../parts/AdminCategoryItem2';
import { ReactSortable } from 'react-sortablejs';

const AdminCatalogCategories = ({categories, setCategories, fetchCategories}) => {
  return (
    categories?.length >= 1 &&
    <div className="row">
        <div className="col-12">
            <ReactSortable
                className="slider-9 mb-3"
                list={categories}
                setList={setCategories}
                group="shared-group"
            >
                {categories.map((card, index)=>
                    <AdminCategoryItem2 key={card.id} card={card} fetchCategories={fetchCategories} index={index}/>
                )}
            </ReactSortable>
        </div>
    </div>
    
  )
}

export default AdminCatalogCategories