import React, { useEffect, useState } from 'react'

const ResizebaleInput = ({title, changeTitle, placeholder, isPublished, ...props}) => {
    const [value, setValue] = useState('');
    const [rows, setRows] = useState(1);

    useEffect(() => {
        setValue(title ? title : '')
    }, [title])

    const handleChange = (event) => {
      setValue(event.target.value);
    };
  
    useEffect(() => {
        const rowCount = Math.ceil(value.length / 60);
        setRows(rowCount < 1 ? 2 : rowCount);
    }, [value]);

    const handleBlur = () => {
        changeTitle(value)
    }

  return (
    <input type="text" 
        value={value} 
        onChange={handleChange}
        placeholder={placeholder ? placeholder : 'Название категории'}
        className={isPublished ? 'admin-input-resizable' : 'admin-input-resizable nonpublished'}
        onBlur={handleBlur}
        {...props}
    >
    </input>
  )
}

export default ResizebaleInput