import React, { useContext } from 'react'
import { useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import QuickProductViewModal from '../modals/QuickProductViewModal'
import ToCartModal from '../modals/ToCartModal'
import { Context } from '../..'
import { NavLink } from 'react-router-dom'
import Utils from '../../services/utils'
import img from "../../images/placeholder.png"
import UserServices from '../../services/general/userServices'
import { observer } from 'mobx-react-lite'

const ProductCard2 = observer(({item, fetchItems}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [show, setShow] = useState(false);

    const {app, user} = useContext(Context);

    const addToWishlist = async () => {
        const newData = {
            uid: user.wishlistUid ? user.wishlistUid : null,
            product_id: item.id
        }

        const res = await UserServices.favorites.toggle(newData);

        if (res.statusCode === 200) {
            if(!user.wishlistUid){
                user.setWishlistUid(res.content.uid);
            }
            fetchItems();
        }
    }

  return (
    <div>
        <div className="product-box-3 h-100 wow fadeInUp">
            <div className="product-header">
                <div className="label-flex">
                    {item.state && 
                        <div className="discount" style={{backgroundColor: `${item.state.subtitle}`}}>
                            <label>{item.state.name}</label>
                        </div>
                    }
                </div>
                {item.bonus && <div className='bonus-flex'>
                        <div className="bonus">
                            <label>
                                {item.bonus.number}
                                <span>{item.bonus.type == 1 ? '%' : ''}</span>
                                <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.72394 2.18445V3.258H2.08998V9.99988H0.748047V2.18445H5.72394ZM1.68203 5.18501H3.64663C4.24066 5.18501 4.7488 5.28521 5.17106 5.48561C5.59333 5.68243 5.91539 5.95976 6.13726 6.31761C6.35912 6.67546 6.47006 7.09235 6.47006 7.56829C6.47006 7.92614 6.40743 8.25357 6.28219 8.55059C6.15694 8.8476 5.97265 9.10525 5.72931 9.32354C5.48597 9.53825 5.18896 9.70465 4.83826 9.82274C4.49115 9.94083 4.09394 9.99988 3.64663 9.99988H0.748047V2.18445H2.10072V8.9317H3.64663C3.98658 8.9317 4.26571 8.86907 4.48399 8.74383C4.70228 8.615 4.86331 8.44681 4.96709 8.23926C5.07445 8.03171 5.12812 7.80447 5.12812 7.55756C5.12812 7.3178 5.07445 7.09951 4.96709 6.90269C4.86331 6.70587 4.70228 6.54842 4.48399 6.43033C4.26571 6.31224 3.98658 6.2532 3.64663 6.2532H1.68203V5.18501Z" fill="white"/>
                                    <path d="M0.748047 0.00146484H5.7229V1.09912H0.748047V0.00146484Z" fill="white"/>
                                </svg>
                            </label>
                        </div>
                    </div>
                }
                {item.additional_product && <div className='present-flex'>
                    <div className='present-text'>{app.localizationsItems?.present_text}</div>
                    <i className='fa fa-gift'></i>
                </div>}
                <div className="product-image">
                    <NavLink to={`/${app.lang}/products/${item.slug}`}>
                        <img src={item.image ? item.image : img} className="img-fluid" alt=""
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img
                            }}
                            loading='lazy'
                        />
                    </NavLink>
                    <ul className="product-option">
                        <li onClick={() => setModalOpen(true)}>
                            <OverlayTrigger placement="top" 
                                overlay={
                                    <Tooltip id="tooltip-view">
                                        {app.localizationsItems?.information}
                                    </Tooltip>
                                }
                            >
                                <i className='iconly-Show icli'></i>
                            </OverlayTrigger>
                        </li>

                        {app.userCabinet &&  <li onClick={addToWishlist}>
                            <OverlayTrigger placement="top" 
                                overlay={
                                    <Tooltip>
                                        {app.localizationsItems?.favorites}
                                    </Tooltip>
                                }
                            >
                                <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg" 
                                    className={item.is_favorite ? 'heart-svg heart-success' : 'heart-svg'}
                                    >
                                    <path d="M6.15 1C3.36914 1 1 3.0793 1 5.7798C1 7.64275 1.87235 9.21118 3.02849 10.5104C4.18066 11.8052 5.66714 12.892 7.01097 13.8016L9.32959 15.3711C9.58351 15.543 9.91649 15.543 10.1704 15.3711L12.489 13.8016C13.8329 12.892 15.3193 11.8052 16.4715 10.5104C17.6277 9.21118 18.5 7.64275 18.5 5.7798C18.5 3.0793 16.1309 1 13.35 1C11.9165 1 10.6552 1.67214 9.75 2.54183C8.84484 1.67214 7.58347 1 6.15 1Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </OverlayTrigger>
                        </li>}
                    </ul>
                </div>
            </div>
            <div className="product-footer">
                <div className="product-detail">
                    <span className="span-name">{item.parent?.title}</span>
                    <NavLink to={`/${app.lang}/products/${item.slug}`}>
                        <h5 className="name">{item.title}</h5>
                    </NavLink>
                    <p className="text-content mt-1 mb-2 product-content">{item.subtitle}</p>
                    <h5 className="price">
                        {(!item.price || item.price == 0) ?
                            <span className="theme-color">{app.localizationsItems?.not_specified}</span> 
                            :
                            <span className="theme-color">{Utils.numberWithSpaces(item.price)} {app.localizationsItems?.currency}</span> 
                        }
                        {item.old_price && <del>{Utils.numberWithSpaces(item.old_price)} {app.localizationsItems?.currency}</del>}
                    </h5>
                    <div className="add-to-cart-box bg-white">
                        {((item.shipment?.slug === "available" && (item.amount && item.amount > 0) || (item.shipment?.slug === "available" && !item.amount))) ?
                            <button className="btn btn-add-cart" onClick={() => setShow(true)}>{app.localizationsItems?.add}
                                <span className="add-icon bg-light-gray">
                                    <i className="fa-solid fa-plus"></i>
                                </span>
                            </button>
                            :
                            <button className="btn btn-add-cart addcart-button">{app.localizationsItems?.not_available}
                                <span>⊘</span>
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
        {modalOpen && <QuickProductViewModal show={modalOpen} setShow={setModalOpen} slug={item.slug}/>}
        {show && <ToCartModal show={show} setShow={setShow} slug={item?.slug}/>}
    </div>
  )
})

export default ProductCard2