import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react'
import { Context } from '../../..';
import FeaturesEditModal from '../modals/FeaturesEditModal';
import DealsDeleteModal from '../modals/DealsDeleteModal';
import AdminCatalogServices from '../../../services/admin/AdminCatalogServices';
import { toast } from 'react-toastify';

const AdminProductFeatures = observer(({features, fetchInfo, item}) => {
    const {app} = useContext(Context);

    const [showEdit, setShowEdit] = useState();
    const [deleteModal, setDeleteModal] = useState();

    const handleDelete = async () => {
        const newData = {
            features: []
        }

        const res = await AdminCatalogServices.products.update(item.id, newData)

        if(res.statusCode === 200){
            fetchInfo();
            toast.success(res.message)
        }else{
            toast.error(res.message)
        }
    }
    
  return (
    <div className="pickup-box admin-banner-hover">
        <div className="product-info">
            <ul className="product-info-list product-info-list-2">
                {features?.map((el, index) =>
                    <li key={index}>{el.title} : {el.value}</li>
                )}
            </ul>
        </div>
        <div className='admin-banner-item-content'>
            <div className='admin-banner-btn edit' onClick={() => setShowEdit(true)}>{app.localizationsItems?.edit}
                <i className='icon-pen'></i>
            </div>
            <div className='admin-banner-btn delete' onClick={() => setDeleteModal(true)}>{app.localizationsItems?.delete}
                <i className='icon-delete'></i>
            </div>
        </div>
        {showEdit &&
            <FeaturesEditModal
                show={showEdit}
                setShow={setShowEdit}
                features={features}
                fetchInfo={fetchInfo}
                item={item}
            />
        }
        {deleteModal &&
            <DealsDeleteModal
                show={deleteModal}
                setShow={setDeleteModal}
                successCallback={handleDelete}
            />
        }
    </div>
  )
})

export default AdminProductFeatures