import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { Context } from '../../..';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import UploadImg from '../ui/UploadImg';
import PageServices from '../../../services/admin/PagesServices';
import Slug from '../ui/Slug';
import Dictionaries from '../../../services/admin/dictionaries';
import CustomSelect from '../../ui/CustomSelect';
import AdminCatalogServices from '../../../services/admin/AdminCatalogServices';
import ModalDiscount from './ModalDiscount';
import TraitEditModal from './TraitEditModal';
import { NavLink } from 'react-router-dom';

const ProductEditModal = observer(({
    show,
    setShow,
    card,
    fetchProducts
}) => {
    const {app} = useContext(Context);

    const handleClose = () => setShow(false);

    const [loading, setLoading] = useState();

    const {register, control, setValue, handleSubmit, formState: {errors}} = useForm();

    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');

    const [productStates, setProductStates] = useState();
    const [state, setState] = useState();

    const [showDiscountModal, setShowDiscountModal] = useState();
    const [showBonusModal, setShowBonusModal] = useState();

    const [categories, setCategories] = useState();
    const [categoriesLoading, setCategoriesLoading] = useState();

    const [loadingStatuses, setLoadingStatuses] = useState();
    const [productStatuses, setProductStatuses] = useState();

    const [discount, setDiscount] = useState();
    const [bonus, setBonus] = useState();

    const [traits, setTraits] = useState();
    const [traitModal, setTraitModal] = useState();
    const [traitIndex, setTraitIndex] = useState();
    
    useEffect(() => {
        if(card){
            setTitle(card.title);
            setSlug(card.slug);
            setValue('price', card.price);
            setValue('image', card.image);
            if(card.discount){
                setValue('number', card.discount.number);
                setValue('type', card.discount.type);
            }
            if(card.bonus){
                setValue('bonus_number', card.bonus.number);
                setValue('bonus_type', card.bonus.type);
            }
            setValue('is_published', card.is_published);
            card.parent && setValue('parent_id', card.parent.id);
            card.shipment && setValue('shipment_id', card.shipment.id)
            card.state && setState(card.state.slug)
            card.traits && setTraits(card.traits)
        }
    }, [card])

    const fetchProductCategories = useCallback(async () => {
        setCategoriesLoading(true);
        try {
            const res = await Dictionaries.productCategories();

            setCategories(res.content);
        } catch (e) {
            toast.error(e.message)
        }
        setCategoriesLoading(false)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchProductCategories();
    }, [fetchProductCategories]);

    const fetchProductStates = async () => {
        try {
            const res = await Dictionaries.productStates();

            setProductStates(res.content);
        } catch (e) {
            toast.error(e.message)
        }
    }

    const fetchProductStatuses = useCallback(async () => {
        setLoadingStatuses(true);

        try {
            const res = await Dictionaries.productStatuses();

            setProductStatuses(res.content);
        } catch (e) {
            toast.error(e.message)
        }

        setLoadingStatuses(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchProductStates()
    }, [])

    useEffect(() => {
        fetchProductStatuses();
    }, [fetchProductStatuses]);

    const customSubmit = async (data) => {
        setLoading(true);

        const {number, type, bonus_number, bonus_type, ...rest} = data;

        const tempDiscount = {
            number, 
            type,
            ...discount
        }
        const tempBonus = {
            bonus_number, 
            bonus_type,
            ...bonus
        }
        
        const newData = {
            discount: discount && tempDiscount,
            bonus: bonus && tempBonus,
            title: title,
            slug: slug,
            state: state,
            traits:traits,
            ...rest
        }

        const res = await AdminCatalogServices.products.update(card.id, newData);

        if(res.statusCode === 200){
            fetchProducts();
            setShow(false);
        }else{
            toast.error(res.message);
        }
        setLoading(false);
    }

    const handleEditTrait = (ind) => {
        setTraitModal(true);
        setTraitIndex(ind);
    }

    const handleAddTrait = () => {
        const tempTrait = {
            title: 'Атрибут',
            value: '',
            elements: [
                {name: '', value: ''}
            ]
        }
        setTraits([...traits, tempTrait])
    }

  return (
    <>
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            {loading &&
                <div className='form-loading'>
                    <div className="isLoading"></div>
                </div>
            }
            <form className='row' onSubmit={handleSubmit(customSubmit)}>
                <div className="col-12">
                    <div className="log-in-title mb-3">
                        <h2>{app.localizationsItems?.edit_product} 
                            <NavLink to={`/${app.lang}/products/${card.slug}`} className={'ms-2'}><i className='fa fa-share'></i></NavLink>
                        </h2>
                        <p>{app.localizationsItems?.image_format}</p>
                    </div>
                    <Controller
                        control={control}
                        name="image"
                        render={({field: {onChange, value}}) => (
                            <UploadImg onChange={onChange} value={value} $error={errors.image}/>
                        )}
                    />
                    <Slug title={title} setTitle={setTitle} slug={slug} setSlug={setSlug}/>
                    <div className="form-floating theme-form-floating mt-3">
                        <input type="text" {...register('price')} className="form-control" placeholder={app.localizationsItems?.price}/>
                        <label>{app.localizationsItems?.price}</label>
                    </div>
                    <div className='mt-3'>
                        {app.localizationsItems?.category}
                            <Controller name="parent_id" control={control}
                                render={({field: {onChange, value}}) => (
                                    <CustomSelect options={categories} 
                                        onChange={onChange} 
                                        placeholder={app.localizationsItems?.category}
                                        value={value}
                                        isLoading={categoriesLoading}
                                    />
                                )}
                            />
                    </div>
                    <div className='mt-3'>
                        {app.localizationsItems?.product_status}
                        <Controller name="shipment_id" control={control}
                                render={({field: {onChange, value}}) => (
                                    <CustomSelect options={productStatuses} onChange={onChange}
                                        value={value}
                                        isLoading={loadingStatuses}
                                        $error={errors.shipment_id}
                                    />
                                )}
                                rules={{required: true}}
                        />
                    </div>
                    <div className='row mt-3'>
                        <div className="col-4">
                            <div className="form-floating theme-form-floating">
                                <input type="text" {...register('number')} className="form-control" placeholder={app.localizationsItems?.discount}/>
                                <label>{app.localizationsItems?.discount}</label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-floating theme-form-floating">
                                <select className='form-select' {...register('type')}>
                                    <option value={1} >%</option>
                                    <option value={2}>₸</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-floating theme-form-floating">
                                <div className='form-control' onClick={() => setShowDiscountModal(true)}>
                                    {app.localizationsItems?.term}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-4">
                            <div className="form-floating theme-form-floating">
                                <input type="text" {...register('bonus_number')} className="form-control" placeholder={app.localizationsItems?.bonus}/>
                                <label>{app.localizationsItems?.bonus}</label>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-floating theme-form-floating">
                                <select className='form-select' {...register('bonus_type')}>
                                    <option value={1} >%</option>
                                    <option value={2}>₸</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-floating theme-form-floating">
                                <div className='form-control' onClick={() => setShowBonusModal(true)}>
                                    {app.localizationsItems?.term}
                                </div>
                            </div>
                        </div>
                    </div>
                    {productStates &&
                        <div className='product-states mt-3'>
                            {productStates?.map((el, index) =>
                                <div className={state === el.value ? 'product-states-item active' : 'product-states-item'}
                                    key={index}
                                    onClick={() => setState(el.value)}
                                >
                                    {el.label}
                                </div>
                            )}
                        </div>
                    }
                    <div className='d-flex mt-3 justify-content-between' onClick={handleAddTrait}>
                        <div>{app.localizationsItems?.attributes}</div>
                        <div className='admin-btn-add'>
                            {app.localizationsItems?.add}
                            <i className='fa fa-plus'></i>
                        </div>
                    </div>
                    {traits?.map((card, index) =>
                        <div className='mt-3 d-flex justify-content-between' key={index}>
                            <div>{card.title}</div>
                            <div className='d-flex gap-1'>
                                {card.elements?.map((el, ind) =>
                                    <span className='admin-trait-item' key={ind}>{el.name}</span>
                                )}
                            </div>
                            <div className='fs-18 d-flex gap-2 admin-btns-cursor'>
                                <i className='icon-pen' onClick={() => handleEditTrait(index)}></i>
                                <i className='icon-delete'></i>
                            </div>
                        </div>
                    )}
                    <div className='d-flex justify-content-between align-items-center mt-3'>
                        {app.localizationsItems?.published}
                        <div className='admin-ckheckout'>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" {...register('is_published')}/>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-end mt-3">
                        <div className="col-auto">
                            <div onClick={handleClose} className="btn btn-md bg-dark cart-button text-white">{app.localizationsItems?.cancel}</div>
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-md btn-animation">{app.localizationsItems?.save}</button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
    {showDiscountModal && 
        <ModalDiscount
            show={showDiscountModal} 
            setShow={setShowDiscountModal}
            discount={discount}
            setDiscount={setDiscount}
        />
    }
    {showBonusModal && 
        <ModalDiscount
            show={showBonusModal} 
            setShow={setShowBonusModal}
            discount={bonus}
            setDiscount={setBonus}
        />
    }
    {traitModal &&
        <TraitEditModal
            show={traitModal}
            setShow={setTraitModal}
            traits={traits}
            setTraits={setTraits}
            traitIndex={traitIndex}
        />
    }
    </>
  )
})

export default ProductEditModal