import http from "./http-common"
import {deleteData, getData, postData} from "../handlers";

export default class PageServices {
    static update = (id, data) => {
        return postData(http, `/pages/${id}`, data);
    }
    static delete = (id) => {
        return deleteData(http, `/pages/${id}`);
    }
    static fields = {
        view: (id, queryString) => {
            return getData(http, `/contentFields/${id}${queryString ? `${queryString}` : ""}`);
        },

        store: (data) => {
            return postData(http, `/contentFields/store`, data);
        },

        update: (id, data) => {
            return postData(http, `/contentFields/${id}`, data);
        },

        delete: (id) => {
            return deleteData(http, `/contentFields/${id}`);
        },

        switchPosition: (id, data) => {
            return postData(http, `/contentFields/switchPosition`, data);
        },
    }
}