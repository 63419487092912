import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';
import AdminCategoryItem from './AdminCategoryItem';
import { ReactSortable } from 'react-sortablejs';
import Dictionaries from '../../../services/admin/dictionaries';
import { toast } from 'react-toastify';

const AdminCategoriesSort = observer(({
    mainCategories,
    setMainCategories,
    fetchCategories
}) => {
    const [categories, setCategories] = useState();

    const fetchProductCategories = useCallback(async () => {
        try {
            const res = await Dictionaries.productCategories();

            setCategories(res.content);
        } catch (e) {
            toast.error(e.message)
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchProductCategories();
    }, [fetchProductCategories]);

  return (
    <div className="row">
        <div className="col-12">
            <ReactSortable
                className="slider-9"
                list={mainCategories}
                setList={setMainCategories}
                group="shared-group"
            >
                {mainCategories.map((card, index)=>
                    <AdminCategoryItem 
                        card={card} 
                        key={card.id}
                        categories={categories}
                        fetchCategories={fetchCategories}
                    />
                )}
            </ReactSortable>
        </div>
    </div>
  )
})

export default AdminCategoriesSort