import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import img from "../../images/placeholder.png"
import { Context } from '../..'

const RecentBox = ({elem, link}) => {
    const {app} = useContext(Context);

  return (
    <div className="recent-box">
        <NavLink to={`/${app.lang}/articles/${elem.slug}`} className="recent-image">
            <img src={elem.preview_image ? elem.preview_image : img} className="img-fluid" alt=""
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
                loading='lazy'
            />
        </NavLink>

        <div className="recent-detail">
            <NavLink to={`/${app.lang}/articles/${elem.slug}`}>
                <h5 className="recent-name">{elem.title}</h5>
            </NavLink>
            <h6>{elem.date}<i data-feather="thumbs-up"></i></h6>
        </div>
    </div>
  )
}

export default RecentBox