import React, { useContext, useEffect, useState } from 'react'
import AddToBasket from '../../simple/AddToBasket'
import { observer } from 'mobx-react-lite'
import { Context } from '../../..'
import Utils from '../../../services/utils'
import UserServices from '../../../services/general/userServices'
import ProductEditModal from '../modals/ProductEditModal'

const AdminProductDetailItem = observer(({
    item,
    fetchInfo
}) => {
    const {app, user} = useContext(Context);

    const [price, setPrice] = useState();
    const [oldPrice, setOldPrice] = useState();
    const [success, setSuccess] = useState();

    const [showEdit, setShowEdit] = useState();

    useEffect(() => {
        setSuccess(item.is_favorite)
    }, [item])

    const addToWishlist = async () => {
        let temp = success;
        setSuccess(!success);
        const newData = {
            uid: user.wishlistUid ? user.wishlistUid : null,
            product_id: item.id
        }

        const res = await UserServices.favorites.toggle(newData);

        if (res.statusCode === 200) {
            if(!user.wishlistUid){
                user.setWishlistUid(res.content.uid);
            }
        }else{
            setSuccess(temp)
        }
    }

  return (
    <div className='admin-banner-hover'>
        {item.discount && 
            <h6 className="offer-top mr-3">{app.localizationsItems?.discount} {Utils.numberWithSpaces(item.discount.number)} {item.discount.type == 1 ? '%' : ''}</h6>
        }
        {item.bonus && 
            <h6 className="offer-top offer-bonus mr-3">{app.localizationsItems?.bonuses} {Utils.numberWithSpaces(item.bonus.number)} {item.bonus.type == 1 ? '%' : ''}</h6>
        }
        {item.additional_product && 
            <h6 className="offer-top offer-present">{app.localizationsItems?.gift} <i className='fa fa-gift'></i></h6>
        }
        <h2 className="name">{item.title}</h2>
        <div className="price-rating">
            <h3 className="theme-color price">
                {!price ?
                    <>{app.localizationsItems?.not_specified}</>
                    :
                    <>{Utils.numberWithSpaces(price)} {app.localizationsItems?.currency} </>
                }
                {oldPrice ? <del className="text-content"> {Utils.numberWithSpaces(oldPrice)} {app.localizationsItems?.currency}</del> : <></>}
            </h3>
        </div>
        <div className='product-card__availability mb-2'>
            {app.localizationsItems?.availability}: {item.shipment &&
                <>
                    {
                        item.shipment.slug === "available" ?
                            <span className="text-success">{item.shipment.name}</span>
                        : item.shipment.slug === "on_wait" ?
                            <span className="text-yellow">{item.shipment.name}</span>
                        :
                            <span className="text-danger">{item.shipment.name}</span>
                    }
                </>
            }
        </div>
        <div className="procuct-contain"dangerouslySetInnerHTML={{__html: item.subtitle}}></div>
        <AddToBasket item={item} price={price} setPrice={setPrice} setOldPrice={setOldPrice}/>
        {app.userCabinet && <div className="buy-box" onClick={addToWishlist}>
            <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg" 
                className={success ? 'heart-svg heart-success' : 'heart-svg'}
                >
                <path d="M6.15 1C3.36914 1 1 3.0793 1 5.7798C1 7.64275 1.87235 9.21118 3.02849 10.5104C4.18066 11.8052 5.66714 12.892 7.01097 13.8016L9.32959 15.3711C9.58351 15.543 9.91649 15.543 10.1704 15.3711L12.489 13.8016C13.8329 12.892 15.3193 11.8052 16.4715 10.5104C17.6277 9.21118 18.5 7.64275 18.5 5.7798C18.5 3.0793 16.1309 1 13.35 1C11.9165 1 10.6552 1.67214 9.75 2.54183C8.84484 1.67214 7.58347 1 6.15 1Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <span>{app.localizationsItems?.add_to_favorites}</span>
        </div>}
        <div className='admin-banner-item-content'>
            <div className='admin-banner-btn edit' onClick={() => setShowEdit(true)}>{app.localizationsItems?.edit}
                <i className='icon-pen'></i>
            </div>
        </div>
        {showEdit && 
            <ProductEditModal
                show={showEdit} 
                setShow={setShowEdit} 
                card={item} 
                fetchProducts={fetchInfo}
            />
        }
    </div>
  )
})

export default AdminProductDetailItem