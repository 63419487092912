import http from "./http-common"
import {getData} from "../handlers";

export default class Dictionaries {
    static productCategories = async (data) => {
        const res = await getData(http,`/dictionaries/productCategories${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static productStatuses = async (data) => {
        const res = await getData(http,`/dictionaries/shipments${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static productCities = async (data) => {
        const res = await getData(http,`/dictionaries/cities${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }
    
    static productStates = async (data) => {
        const res = await getData(http,`/dictionaries/productStates${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions2(res)
    }
    
    

    static deliveriesTransformToOptions = ({content, ...props}) => {
        const newContent = content.map((item) => {
            const {name, id, value, ...rest} = item;

            return {
                label: name,
                value: String(id),
                types: value,
                ...rest
            }
        })

        return {
            ...props,
            content: newContent
        }
    }

    static transformToOptions = ({content, ...props}) => {
        const newContent = content.map((item) => {
            const {name, id, ...rest} = item;

            return {
                label: name,
                value: String(id),
                ...rest
            }
        })

        return {
            ...props,
            content: newContent
        }
    }

    static transformToOptions2 = ({content, ...props}) => {
        const newContent = content.map((item) => {
            const {name, slug, ...rest} = item;

            return {
                label: name,
                value: slug
            }
        })

        return {
            ...props,
            content: newContent
        }
    }

    static transformToUsers = ({content, ...props}) => {
        const newContent = content.items?.map((item) => {
            const {name, phone, email, id} = item;

            return {
                label: `${name ? `${name},` : ''} ${phone ? phone : email}`,
                value: String(id),
                name, phone, email,
            }
        })

        return {
            ...props,
            total_pages: content.total_pages,
            content: newContent
        }
    }

    static transformAvialableFieldsToOptions = ({content, ...props}) => {
        const newContent = content.map((item) => {
            const {title, slug, ...rest} = item;

            return {
                label: title,
                value: slug,
                ...rest
            }
        })

        return {
            ...props,
            content: newContent
        }
    }

    static transformBankbooksToOptions = ({content, ...props}) => {
        const newContent = content.map((item) => {
            const {number, id, ...rest} = item;

            return {
                label: number,
                value: String(id),
                ...rest
            }
        })

        return {
            ...props,
            content: newContent
        }
    }

    static transformBankbooksToPaginateOptions = ({content, ...props}) => {
        const {items, current_page, total_pages} = content;
        const newItems = items.map((item) => {
            const {number, id, ...rest} = item;

            return {
                label: number,
                value: String(id),
                ...rest
            }
        })

        const newContent = {
            hasMore: total_pages > current_page,
            options: newItems
        }

        return {
            ...props,
            content: newContent
        }
    }
}