import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import AdminCatalogServices from '../../../services/admin/AdminCatalogServices';

const FeaturesEditModal = observer(({
    show,
    setShow,
    features,
    fetchInfo,
    item
}) => {
    const {app} = useContext(Context);
    const [loading, setLoading] = useState();

    const handleClose = () => setShow(false);

    const {register, control, setValue, handleSubmit, formState: {errors}} = useForm();

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'items'
    });

    useEffect(() => {
        if(features){
            setValue('items', features)
        }
    }, [features])
    

    const customSubmit = async (data) => {
        setLoading(true);
        
        const newData = {
            features: data.items
        }

        const res = await AdminCatalogServices.products.update(item.id, newData)

        if(res.statusCode === 200){
            fetchInfo();
            toast.success(res.message)
        }else{
            toast.error(res.message)
        }

        setLoading(false);
    }

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            {loading &&
                <div className='form-loading'>
                    <div className="isLoading"></div>
                </div>
            }
            <form className='row' onSubmit={handleSubmit(customSubmit)}>
                <div className="col-12">
                    <div className="log-in-title mb-3">
                        <h2>{app.localizationsItems?.feature_edit}</h2>
                    </div>
                    {fields?.map((el, index) =>
                        <div className='d-flex align-items-center gap-2 mt-2' key={index}>
                            <input placeholder={app.localizationsItems?.naiming} type="text" className='form-control' {...register(`items[${index}].title`)}/>
                            <input placeholder={app.localizationsItems?.value} type="text" className='form-control' {...register(`items[${index}].value`)}/>
                            <div className="fs-18"><i className='icon-delete' onClick={() => remove(index)}></i></div>
                        </div>
                    )}
                    <div onClick={() => append({ title: '', value: '' })} className='btn mt-3'>
                        {app.localizationsItems?.add} <i className='fa fa-plus'></i>
                    </div>
                    <div className="row justify-content-end mt-3">
                        <div className="col-auto">
                            <div onClick={handleClose} className="btn btn-md bg-dark cart-button text-white">{app.localizationsItems?.cancel}</div>
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-md btn-animation">{app.localizationsItems?.save}</button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default FeaturesEditModal