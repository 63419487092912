import React, { useContext, useState } from 'react'
import ImageUpload from "../../../services/admin/imageUpload";
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { Context } from '../../..';

const UploadImg = observer(({onChange, value}) => {
    const {app} = useContext(Context);
    const [loading, setLoading] = useState();

    const removeImage = (e) => {
        e.preventDefault();
        onChange('');
    }

    const uploadImg = async (e) => {
        const currentFile = e.target.files[0];
        setLoading(true);

        const res = await ImageUpload.upload(currentFile);

        if (res.statusCode === 200) {
            toast.success(res.message)
            onChange(res.content.value);
        } else {
            toast.error(res.message)
        }
        setLoading(false);
    }

  return (
    <div className='upload-img'>
        <input type="file" name="files" accept="image/jpeg, image/png, image/svg, image/gif, image/webp, image/jpg, text/css, text/js" 
            onChange={e => uploadImg(e)}
        />
        {value ?
            <div className='upload-img-content'>
                <img src={'https://shop-003-003.panama.kz'+value} alt="" loading='lazy'/>
                <span onClick={e => removeImage(e)}>
                    <i className='icon-trash'></i>
                </span>
            </div>
            :
            <div className='upload-img-wrapper'>
                <i className='icon-download'></i>
                <div>{app.localizationsItems?.upload}</div>
            </div>
        }
    </div>
  )
})

export default UploadImg