import React from 'react'
import img from "../../images/placeholder.png"

const DeliveryBox = ({el}) => {
  return (
    <div className="delivery-box">
        <div className="delivery-icon">
            <img src={el.image} alt=""
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
                loading='lazy'
            />
        </div>

        <div className="delivery-detail">
            <h5 className="text">{el.title}</h5>
        </div>
    </div>
  )
}

export default DeliveryBox