import React, { useContext, useState } from 'react'
import Utils from '../../services/utils'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'
import img from "../../images/placeholder.png"
import { NavLink } from 'react-router-dom'
import ToCartModal from '../modals/ToCartModal'

const BestsellerCard = observer(({card}) => {
    const {app} = useContext(Context);
    const [show, setShow] = useState();

  return (
    <div className="deal-box2 wow fadeInUp">
        <NavLink to={`/${app.lang}/products/${card.slug}`} className="category-image order-sm-2">
            <img src={card.image ? card.image : img} className="img-fluid" alt=""
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
                loading='lazy'
            />
        </NavLink>

        <div className="deal-detail order-sm-1">
            <button className="buy-box btn theme-bg-color text-white btn-cart" onClick={() => setShow(true)}>
                <i className="iconly-Buy icli m-0"></i>
            </button>
            {card.state && 
                <div className="hot-deal" style={{backgroundColor: `${card.state.subtitle}`}}>
                    <span>{card.state.name}</span>
                </div>
            }
            <NavLink to={`/${app.lang}/products/${card.slug}`} className="text-title">
                <h5>{card.title}</h5>
            </NavLink>
            <h5 className="price">{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency} 
                {card.old_price && <span>{Utils.numberWithSpaces(card.old_price)} {app.localizationsItems?.currency}</span>}
            </h5>
            <div className="progress custom-progressbar">
                <div className="progress-bar" role="progressbar"
                    aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <h4 className="item">Продано: <span>30 шт</span></h4>
            <h4 className="offer">{card.subtitle}</h4>
            <div className="timer" id="clockdiv-4" data-hours="1" data-minutes="2" data-seconds="3">
                <ul>
                    <li>
                        <div className="counter">
                            <div className="days">
                                <h6></h6>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="counter">
                            <div className="hours">
                                <h6></h6>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="counter">
                            <div className="minutes">
                                <h6></h6>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="counter">
                            <div className="seconds">
                                <h6></h6>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        {show && <ToCartModal show={show} setShow={setShow} slug={card?.slug}/>}
    </div>
  )
})

export default BestsellerCard