import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import { Context } from '../..';
import img from "../../images/placeholder.png"

const BlogBox = ({card}) => {
    const {app} = useContext(Context);

  return (
    <div className="blog-box">
        <div className="blog-box-image">
            <div className="blog-image">
                <NavLink to={`/${app.lang}/articles/${card.slug}`} className="rounded-3">
                    <img src={card.image} className="bg-img" alt=""
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img
                        }}
                        loading='lazy'
                    />
                </NavLink>
            </div>
        </div>

        <NavLink to={`/${app.lang}/articles/${card.slug}`} className="blog-detail d-block">
            {card.parent?.title && <h6>{card.parent.title}</h6>}
            <h5>{card.title}</h5>
        </NavLink>
    </div>
  )
}

export default BlogBox