import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form';
import UploadImg from '../ui/UploadImg';
import { observer } from 'mobx-react-lite';
import { Context } from '../../..';
import PageServices from '../../../services/admin/PagesServices';
import { toast } from 'react-toastify';

const AdvantagesEditModal = observer(({
    show,
    setShow,
    card,
    banners,
    setBanners,
    id,
    item,
    index
}) => {
    const {app} = useContext(Context);

    const [loading, setLoading] = useState();

    const handleClose = () => setShow(false);

    const {register, control, setValue, handleSubmit, formState: {errors}} = useForm();

    useEffect(() => {
        if(card){
            setValue('image', card.image);
            setValue('title', card.title);
        }
    }, [card])

    const customSubmit = async (data) => {
        setLoading(true);

        banners[index] = data

        const newData = {
            parent_id: id,
            type: item.type,
            value: {
                type: item.type,
                title: item.title,
                is_background: item.is_background,
                elements: [
                    ...banners
                ]
            }
        }

        const res = await PageServices.fields.update(item.id, newData);

        if(res.statusCode === 200){
            setBanners([...newData.value.elements])
            setShow(false);
        }else{
            toast.error(res.message)
        }
        setLoading(false);
    }

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            {loading &&
                <div className='form-loading'>
                    <div className="isLoading"></div>
                </div>
            }
            <form className='row' onSubmit={handleSubmit(customSubmit)}>
                <div className="col-12">
                    <div className="log-in-title mb-3">
                        <h2>{app.localizationsItems?.editing}</h2>
                        <p>{app.localizationsItems?.image_format}</p>
                    </div>
                    <Controller
                        control={control}
                        name="image"
                        render={({field: {onChange, value}}) => (
                            <UploadImg onChange={onChange} value={value} $error={errors.image}/>
                        )}
                    />
                    <div className="form-floating theme-form-floating mt-3">
                        <input type="text" {...register('title')} className="form-control" placeholder={app.localizationsItems?.title}/>
                        <label>{app.localizationsItems?.title}</label>
                    </div>
                    <div className="row justify-content-end mt-3">
                        <div className="col-auto">
                            <div onClick={handleClose} className="btn btn-md bg-dark cart-button text-white">{app.localizationsItems?.cancel}</div>
                        </div>
                        <div className="col-auto">
                            <button type="submit" className="btn btn-md btn-animation">{app.localizationsItems?.save}</button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
})

export default AdvantagesEditModal