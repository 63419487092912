import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Utils from '../../services/utils';
import UserServices from '../../services/general/userServices';
import img from "../../images/placeholder.png"
import { Context } from '../..';
import CartIconItem from '../simple/CartIconItem';
import { observer } from 'mobx-react-lite';

const CartIcon = observer(() => {
    const {app, user} = useContext(Context);

    const [active, setActive] = useState();

    const navigate = useNavigate();

    const fetchItems = async () => {
        const res = user.uid ? await UserServices.cart.index(user.uid) : await UserServices.cart.index();

        if(res.statusCode === 200){
            res.content?.products ? user.setCartProducts(res.content.products) : user.setCartProducts([])
            res.content?.total_price ? user.setTotalSum(res.content.total_price) : user.setTotalSum(0)

            if(res.content?.uid && (res.content?.uid !== user.uid)){
                user.setUid(res.content?.uid)
            }
        }
    }

    useEffect(() => {
        fetchItems();
    }, [user.basketChange])

  return (
    <>
        <li className="onhover-dropdown">
            <NavLink to={`/${app.lang}/basket`} className="header-icon bag-icon">
                <small className="badge-number">{user.cartProducts?.length ? user?.cartProducts?.length : 0}</small>
                <i className="iconly-Bag-2 icli"></i>
            </NavLink>
            <div className="onhover-div">
                {user.cartProducts?.length >= 1 ?
                    <ul className="cart-list">
                        {user.cartProducts?.map((card, index) =>
                            <CartIconItem key={index} card={card}/>
                        )}
                    </ul>
                    :
                    <>{app.localizationsItems?.basket_title}</>
                }
                <div className="price-box">
                    <h5>{app.localizationsItems?.outcome} :</h5>
                    <h4 className="theme-color fw-bold">{Utils.numberWithSpaces(user.totalSum)} {app.localizationsItems?.currency}</h4>
                </div>

                <div className="button-group">
                    <NavLink to={`/${app.lang}/basket`} className="btn btn-sm cart-button">{app.localizationsItems?.view_cart}</NavLink>
                    <button onClick={() => navigate(`/${app.lang}/checkout`)} disabled={!user?.cartProducts?.length} className="btn btn-sm cart-button theme-bg-color text-white">{app.localizationsItems?.to_check}</button>
                </div>
            </div>
        </li>
        {user.cartProducts?.length >= 1 &&
            <>
                <div className="button-item">
                    <button className="item-btn btn text-white" onClick={() => setActive(true)}>
                        <i className="iconly-Bag-2 icli"></i>
                    </button>
                </div>
                <div className={active ? "item-section active" : "item-section"}>
                    <button className="close-button" onClick={() => setActive(false)}>
                        <i className="fas fa-times"></i>
                    </button>
                    <h6>
                        <i className="iconly-Bag-2 icli"></i>
                        <span>{user.cartProducts.length} {app.localizationsItems?.count}</span>
                    </h6>
                    <ul className="items-image">
                        {user.cartProducts.slice(0, 2).map((card, index) => 
                            <li key={index}>
                                <img src={card.image} alt=""
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; 
                                        currentTarget.src = img
                                    }}
                                    loading='lazy'
                                />
                            </li>
                        )}
                        {user.cartProducts?.length > 2 &&
                            <li>+{Number(user.cartProducts.length) - 2}</li>
                        }
                    </ul>
                    <button onClick={() => navigate(`/${app.lang}/basket`)} className="btn item-button btn-sm fw-bold">
                        {Utils.numberWithSpaces(user.totalSum)} {app.localizationsItems?.currency}
                    </button>
                </div>
            </>
        }
    </>
  )
})

export default CartIcon