import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import img from "../../../images/placeholder.png"
import { NavLink, useNavigate } from 'react-router-dom';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';
import BannerEditModal from '../modals/BannerEditModal';
import DealsDeleteModal from '../modals/DealsDeleteModal';
import PageServices from '../../../services/admin/PagesServices';
import { toast } from 'react-toastify';

const AdminMainBanner = observer(({item, id}) => {
    const {app} = useContext(Context);

    const [banners, setBanners] = useState([]);

    const navigate = useNavigate();

    const [showEdit, setShowEdit] = useState();
    const [selectBanner, setSelectBanner] = useState();

    const [deleteModal, setDeleteModal] = useState();

	useEffect(() => {
		if(item?.elements){
            setBanners(item.elements)
		}
	}, [item?.elements])

    const handleEdit = (value, index) => {
        setShowEdit(true);
        setSelectBanner({
            index: index, 
            value:value
        })
    }

    const handleDelete = async() => {
        banners[selectBanner] = {
            title: '',
            description: null,
            link: null,
            subtitle: null,
            subtitle_top: null,
            image: null
        }

        const newData = {
            parent_id: id,
            type: "opening-4",
            value: {
                type: "opening-4",
                elements: [
                    ...banners
                ]
            }
        }

        const res = await PageServices.fields.update(item.id, newData);
        
        if(res.statusCode === 200){
            setBanners([...newData.value.elements]);
            setDeleteModal(false);
        }else{
            toast.error(res.message);
        }
    }

  return (
	<section className="home-section-2 home-section-small section-b-space banner-main-hover" id='stories-banner'
        style={{backgroundImage: `url(https://shop-003-003.panama.kz${banners[4]?.image})`}}
    >
        <div className='banner-main-btns'>
            <div className='banner-main-pen' onClick={() => handleEdit(banners[4], 4)}><i className='icon-pen'></i></div>
            <div className='banner-main-pen' onClick={() => {setSelectBanner(4); setDeleteModal(true)}}><i className='icon-delete'></i></div>
        </div>
		<div className="container-fluid-lg">
			<div className="row g-4">
				{banners[0] &&
					<div className="col-xxl-6 col-md-8">
						<div className="home-contain admin-banner-hover h-100" onClick={() => banners[0].link && navigate(banners[0].link)}>
							<img src={'https://shop-003-003.panama.kz'+banners[0].image} className="img-fluid bg-img position-absolute" alt=""
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                                loading='lazy'
                            />
							<div className="home-detail home-width p-center-left position-relative">
								<div style={{color: banners[0].banner_font_color}}>
									<h6 className="ls-expanded theme-color" dangerouslySetInnerHTML={{__html: banners[0].subtitle_top}}></h6>
									<h1 className="fw-bold w-100" dangerouslySetInnerHTML={{__html: banners[0].title}}></h1>
									<h3 className="text-content fw-light" dangerouslySetInnerHTML={{__html: banners[0].subtitle}}></h3>
									<p className="d-sm-block d-none" dangerouslySetInnerHTML={{__html: banners[0].description}}></p>
									{banners[0].link &&
                                        <button onClick={() => navigate(banners[0].link)} className="btn bg-white theme-color mt-3 home-button btn-2">{app.localizationsItems?.buy_now}</button>
                                    }
								</div>
							</div>
                            <div className='admin-banner-item-content'>
                                <div className='admin-banner-btn edit' onClick={() => handleEdit(banners[0], 0)}>{app.localizationsItems?.edit}
                                    <i className='icon-pen'></i>
                                </div>
                                <div className='admin-banner-btn delete' onClick={() => {setSelectBanner(0); setDeleteModal(true)}}>{app.localizationsItems?.delete}
                                    <i className='icon-delete'></i>
                                </div>
                            </div>
						</div>
					</div>
				}
				{banners[1] &&
					<div className="col-xxl-3 col-md-4 ratio_medium d-md-block d-none">
						<div className="home-contain home-small admin-banner-hover h-100" onClick={() => banners[1].link && navigate(banners[1].link)}>
							<div className="h-100">
								<img src={'https://shop-003-003.panama.kz'+banners[1].image} className="img-fluid bg-img" alt=""
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; 
                                        currentTarget.src = img
                                    }}
                                    loading='lazy'
                                />
							</div>
							<div className="home-detail text-center p-top-center w-100 text-white">
								<div style={{color: banners[1].banner_font_color}}>
									<h4 className="fw-bold" dangerouslySetInnerHTML={{__html: banners[1].title}}></h4>
									<h5 className="text-center" dangerouslySetInnerHTML={{__html: banners[1].subtitle}}></h5>
									{banners[1].link &&
                                        <button onClick={() => navigate(banners[1].link)} className="btn bg-white theme-color mt-3 home-button mx-auto btn-2">{app.localizationsItems?.buy_now}</button>
                                    }
								</div>
							</div>
                            <div className='admin-banner-item-content'>
                                <div className='admin-banner-btn edit' onClick={() => handleEdit(banners[1], 1)}>{app.localizationsItems?.edit}
                                    <i className='icon-pen'></i>
                                </div>
                                <div className='admin-banner-btn delete' onClick={() => {setSelectBanner(1); setDeleteModal(true)}}>{app.localizationsItems?.delete}
                                    <i className='icon-delete'></i>
                                </div>
                            </div>
						</div>
					</div>
				}
				<div className="col-xxl-3 ratio_65 d-xxl-block d-none">
					<div className="row g-3 banners-height">
						{banners[2] &&
							<div className="col-xxl-12 col-sm-6 banner-itm">
								<div className="home-contain admin-banner-hover" onClick={() => banners[2].link && navigate(banners[2].link)}>
									<NavLink to={banners[2].link}>
										<img src={'https://shop-003-003.panama.kz'+banners[2].image} className="img-fluid bg-img" alt=""
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; 
                                                currentTarget.src = img
                                            }}
                                            loading='lazy'
                                        />
									</NavLink>
									<div className="home-detail text-white w-50 p-center-left home-p-sm">
										<div style={{color: banners[2].banner_font_color}}>
											<h4 className="fw-bold" dangerouslySetInnerHTML={{__html: banners[2].title}}></h4>
											<h5 dangerouslySetInnerHTML={{__html: banners[2].subtitle}}></h5>
                                            {banners[2].link &&
                                                <button onClick={() => navigate(banners[2].link)} className="btn bg-white theme-color mt-3 home-button mx-auto btn-2">{app.localizationsItems?.buy_now}</button>
                                            }
										</div>
									</div>
                                    <div className='admin-banner-item-content'>
                                        <div className='admin-banner-btn edit' onClick={() => handleEdit(banners[2], 2)}>{app.localizationsItems?.edit}
                                            <i className='icon-pen'></i>
                                        </div>
                                        <div className='admin-banner-btn delete' onClick={() => {setSelectBanner(2); setDeleteModal(true)}}>{app.localizationsItems?.delete}
                                            <i className='icon-delete'></i>
                                        </div>
                                    </div>
								</div>
							</div>
						}
						{banners[3] && 
							<div className="col-xxl-12 col-sm-6 banner-itm">
								<div className="home-contain admin-banner-hover" onClick={() => banners[3].link && navigate(banners[3].link)}>
									<NavLink to={banners[3].link}>
										<img src={'https://shop-003-003.panama.kz'+banners[3].image} className="img-fluid bg-img" alt=""
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; 
                                                currentTarget.src = img
                                            }}
                                            loading='lazy'
                                        />
									</NavLink>
									<div className="home-detail text-white w-50 p-center-left home-p-sm">
										<div style={{color: banners[3].banner_font_color}}>
											<h4 className="fw-bold" dangerouslySetInnerHTML={{__html: banners[3].title}}></h4>
											<h5 dangerouslySetInnerHTML={{__html: banners[3].subtitle}}></h5>
										</div>
									</div>
                                    <div className='admin-banner-item-content'>
                                        <div className='admin-banner-btn edit' onClick={() => handleEdit(banners[3], 3)}>{app.localizationsItems?.edit}
                                            <i className='icon-pen'></i>
                                        </div>
                                        <div className='admin-banner-btn delete' onClick={() => {setSelectBanner(3); setDeleteModal(true)}}>{app.localizationsItems?.delete}
                                            <i className='icon-delete'></i>
                                        </div>
                                    </div>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
        {showEdit && 
            <BannerEditModal 
                show={showEdit}
                setShow={setShowEdit}
                banner={selectBanner}
                banners={banners}
                id={id}
                setBanners={setBanners}
                subId={item.id}
            />
        }
        {deleteModal &&
            <DealsDeleteModal
                show={deleteModal}
                setShow={setDeleteModal}
                successCallback={handleDelete}
            />
        }
	</section>
  )
})

export default AdminMainBanner