import React, { useContext, useEffect } from 'react'
import Slider from "react-slick";
import { useState } from 'react'
import Fancybox from '../../complex/FancyBox'
import img from "../../../images/placeholder.png"
import { observer } from 'mobx-react-lite';
import { Context } from '../../..';
import GalleryEditModal from '../modals/GalleryEditModal';

const AdminProductSlider = observer(({gallery, image, id}) => {
    const {app} = useContext(Context);

    const [images, setImages] = useState();

    const [showEdit, setShowEdit] = useState();

    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);

    useEffect(() => {
        if(gallery){
            setImages(gallery)
        }
    }, [])

    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: slider1,
    }

    const settings2 = {
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: slider2,
        dots: false,
        focusOnSelect: true,
        infinite: false,
        arrows: true,
        responsive: [{
                breakpoint: 1400,
                settings: {
                    vertical: false,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    vertical: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    vertical: false,
                }
            }, {
                breakpoint: 430,
                settings: {
                    slidesToShow: 3,
                    vertical: false,
                }
            },
        ]
    }

  return (
    <div className="product-left-box admin-mini-edit-hover">
        <div className='admin-mini-edit'>
            <div className='admin-banner-btn edit' onClick={() => setShowEdit(true)}>{app.localizationsItems?.edit}
                <i className='icon-pen'></i>
            </div>
        </div>
        <div className="row g-sm-4 g-2">
            <div className="col-12">
                <Fancybox
                    options={{
                        Carousel: {
                        infinite: false,
                        },
                    }}
                >
                    {images ?
                        <Slider className="product-main no-arrow" {...settings} ref={(slider) => setSlider2(slider)}>
                            {images.map((image, index) =>
                                <div className="slider-image" key={index}>
                                        <a href={image} data-width="700" data-height="700"  target="_blank" data-fancybox="gallery">
                                            <img src={image ? 'https://shop-003-003.panama.kz'+image : img} id="img-1" data-zoom-image={image} className="img-fluid image_zoom_cls-0" alt=""
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; 
                                                    currentTarget.src = img
                                                }}
                                                loading='lazy'
                                            />
                                        </a>
                                </div>
                            )}
                        </Slider>
                        :
                        <div className="slider-image">
                                <a href={image} data-width="700" data-height="700"  target="_blank" data-fancybox="gallery">
                                    <img src={image ? 'https://shop-003-003.panama.kz'+image : img} id="img-1" data-zoom-image={image} className="img-fluid image_zoom_cls-0" alt=""
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src = img
                                        }}
                                        loading='lazy'
                                    />
                                </a>
                        </div>
                    }
                </Fancybox>
            </div>

            {images && <div className="col-12 mt-1">
                <Slider className="left-slider-image left-slider slick-top" {...settings2} ref={(slider) => setSlider1(slider)}>
                    {images.map((image, index) =>
                        <div className="sidebar-image" key={index}>
                            <img src={image ? 'https://shop-003-003.panama.kz'+image : img} className="img-fluid" alt=""
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                                loading='lazy'
                            />
                        </div>
                    )}
                </Slider>
            </div>}
        </div>
        {showEdit &&
            <GalleryEditModal
                show={setShowEdit}
                setShow={setShowEdit}
                images={images}
                setImages={setImages}
                id={id}
            />
        }
    </div>
  )
})

export default AdminProductSlider
