import React, { useContext, useEffect, useState } from 'react'
import img from "../../../images/app_mob.png"
import img2 from "../../../images/mob_app.png"
import { observer } from 'mobx-react-lite'
import appStore from "../../../images/appstore1.png"
import playMarket from "../../../images/playstore1.png"
import { Context } from '../../..'

const AppMobBanner = observer(() => {
    const {app} = useContext(Context);

    const [appStoreLink, setAppStoreLink] = useState();
    const [googlePlayLink, setGooglePlayLink] = useState();
    
    useEffect(() => {
        if(app.settings){
            setAppStoreLink(app.settings.find(it => it.type === "app-store-link")?.value);
            setGooglePlayLink(app.settings.find(it => it.type === "google-play-link")?.value);
        }
    }, [app.settings])

  return (
    <div className='app-mob-banner'>
        <div className='container'>
            {(appStoreLink || googlePlayLink) ?
                <div className='app-mob-banner-flex'>
                    <div>
                        <h2>{app.localizationsItems?.available_app_links}:</h2>
                        <div className="description-flex">
                            {appStoreLink && <a href={appStoreLink} target="_blank" rel="noopener">
                                <img src={appStore} alt="" loading="lazy"/>
                            </a>}
                            {googlePlayLink && <a href={googlePlayLink} target="_blank" rel="noopener">
                                <img src={playMarket} alt="" loading="lazy"/>
                            </a>}
                        </div>
                    </div>
                    <img src={img2} alt="" loading='lazy'/>
                </div>
                :
                <div className='app-mob-banner-wrapper'>
                    <h2>{app.localizationsItems?.app_collect}</h2>
                    <img src={img} alt="" loading='lazy'/>
                </div>
            }
        </div>
    </div>
  )
})

export default AppMobBanner