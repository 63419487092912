import React, { useContext, useEffect, useState } from 'react'
import Slider from 'react-slick'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import CatalogServices from '../../../services/general/catalogServices'
import AdminProductCard2 from '../parts/AdminProductCard2'
import ResizebaleInput from '../ui/ResizebleInput'
import PageServices from '../../../services/admin/PagesServices'
import { toast } from 'react-toastify'

const AdminRecentViewProducts = observer(({item, id}) => {
    const {app} = useContext(Context);
    const [loading, setLoading] = useState();
    const [items, setItems] = useState();

    const [isPublished, setIsPublished] = useState();
    
    const [slidesToShow, setSlidesToShow] = useState(6)

    const fetchProducts = async () => {
        setLoading(true);
        let str = ''
        let names = JSON.parse(localStorage.getItem("recent_products"));
        names?.forEach(element => {
            str += `ids[]=${element}&` 
        });
        const res = await CatalogServices.products.index(`${str}`);

        if(res.statusCode === 200){
            setItems(res.content);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchProducts()
    }, [app.cityString])

    useEffect(() => {
        if(app.mobView){
            setSlidesToShow(2)
        }else{
            setSlidesToShow(6)
        }
    }, [app.mobView]);

    const changeTitle = async (name) => {
        const newData = {
            parent_id: id,
            type: "recent-products",
            value: {
                type: "recent-products",
                elements: null,
                title: name
            }
        }

        const res = await PageServices.fields.update(item.id, newData);
        
        if(res.statusCode !== 200){
            toast.error(res.message);
        }
    }

  return (
    (localStorage.getItem("recent_products") && items?.items) &&
	<section className="product-list-section section-b-space">
		<div className="container-fluid-lg">
			<div className="category-item-top title">
                <ResizebaleInput title={item?.title} changeTitle={changeTitle} isPublished={isPublished}/>
                <div className="category-item-btns">
                    <div className="category-item-btns-item">
                        <i className='fa fa-plus'></i>
                    </div>
                    <div className="category-item-btns-item">
                        <i className={isPublished ? "icon-visibility" : "icon-visibility_off"}></i>
                    </div>
                    <div className="category-item-btns-item"><i className="icon-chevron-down"></i></div>
                    <div className="category-item-btns-item"><i className="icon-chevron-up"></i></div>
                    <div className="category-item-btns-item"><i className="icon-delete"></i></div>
                </div>
			</div>
			<div className="row">
				<div className="col-12">
                    <Slider className="slider-6_1 product-wrapper"
                        arrows={false}
                        infinite={false}
                        slidesToShow={slidesToShow}
                        slidesToScroll={3}
                        dots={true}
                        responsive = {[{
                                breakpoint: 1430,
                                settings: {
                                    slidesToShow: 5,
                                    autoplay: true,
                                    autoplaySpeed: 3500,
                                }
                            },
                            {
                                breakpoint: 1199,
                                settings: {
                                    slidesToShow: 4,
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 3,
                                }
                            },
                            {
                                breakpoint: 600,
                                settings: {
                                    slidesToShow: 2,
                                }
                            },
                        ]}
                    >
                        {items.items.map((card, index) =>
                            <AdminProductCard2 item={card} key={index}/>
                        )}
                    </Slider>
                </div>
            </div>
        </div>
    </section>
  )
})

export default AdminRecentViewProducts