import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import CatalogServices from '../../services/general/catalogServices';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import AddToBasket from '../simple/AddToBasket';
import { NavLink } from 'react-router-dom';
import img from "../../images/placeholder.png"
import Utils from '../../services/utils';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const QuickProductViewModal = observer(({show, setShow, slug}) => {
    const {app} = useContext(Context);

    const handleClose = () => setShow(false);

    const [price, setPrice] = useState();
    const [oldPrice, setOldPrice] = useState();

	const {info, loading} = useFetchInfo(CatalogServices.products.view, slug);

    useEffect(() => {
        if(info){
            let names = localStorage.getItem("recent_products") ? JSON.parse(localStorage.getItem("recent_products")) : [];
            if(names.length >= 12){
                names.splice(0, 1);
            }
            if(!names.find(it => it === info.id)){
                names.push(info.id);
            }
            localStorage.setItem('recent_products', JSON.stringify(names))
        }
    }, [info])

  return (
    info &&
	<Modal show={show} onHide={handleClose} className="modal fade theme-modal view-modal" centered size="xl">
		<Modal.Header className="p-0">
			<button type="button" className="btn-close" onClick={handleClose}>
				<i className="fa-solid fa-xmark"></i>
			</button>
		</Modal.Header>
		<Modal.Body >
			<div className="row g-sm-4 g-2">
				<div className="col-lg-6">
					<div className="slider-image">
						<img src={info.preview_image ? info.preview_image : img} className="img-fluid" alt=""
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = img
                            }}
                            loading='lazy'
                        />
					</div>
				</div>

				<div className="col-lg-6">
					<div className="right-sidebar-modal">
						<NavLink to={`/${app.lang}/products/${slug}`}>
                            <h4 className="title-name">{info.title}</h4>
                        </NavLink>
						{!price ?
                            <h4 className="price">{app.localizationsItems?.not_specified}</h4>
                            :
                            <h4 className="price">{Utils.numberWithSpaces(price)} {app.localizationsItems?.currency}
                                {oldPrice ? <del className='min-price'>{Utils.numberWithSpaces(oldPrice)} {app.localizationsItems?.currency}</del> : <></>}
                            </h4>
                        }
                        {info.shipment && <div className='product-card__availability mb-2'>
                            {app.localizationsItems?.availability}: 
                                {
                                    info.shipment.slug === "available" ?
                                        <span className="text-success">{info.shipment.name}</span>
                                    : info.shipment.slug === "on_wait" ?
                                        <span className="text-yellow">{info.shipment.name}</span>
                                    :
                                        <span className="text-danger">{info.shipment.name}</span>
                                }
                        </div>}
                        {info.additional_product &&
                            <NavLink to={`/${app.lang}/products/${info.additional_product.slug}`} className='present-mini'>
                                <i className='fa fa-gift'></i> {app.localizationsItems.as_gift}: 
                                <span>{info.additional_product.title}</span>
                            </NavLink>
                        }
						<div className="product-rating"></div>

						<div className="product-detail">
							<h4>{app.localizationsItems?.product_information} :</h4>
							<p dangerouslySetInnerHTML={{__html: info.subtitle}}></p>
						</div>

						{info.features && <ul className="brand-list">
                            {info.features.map((it, index) =>
                            	<li key={index}>
                                    <div className="brand-box">
                                        <h5>{it.title}:</h5>
                                        <h6>{it.value}</h6>
                                    </div>
                                </li>
                            )}
						</ul>}
                        <div className='mt-3'/>
                        <AddToBasket item={info} price={price} setPrice={setPrice} setOldPrice={setOldPrice}/>
					</div>
				</div>
			</div>
		</Modal.Body>
	</Modal>
  )
})

export default QuickProductViewModal