import React, { useEffect, useRef, useState } from 'react'
import { MapContainer, Marker, Polygon, Popup, Tooltip, TileLayer, useMapEvents } from 'react-leaflet'
import AppServices from '../../services/general/appServices';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';

const MapComponent = observer(({setAddress, deliveryZones, setAddressValue}) => {
    const mapRef = useRef();
    const [center, setCenter] = useState();

    const [location, setLocation] = useState();

    useEffect(() => {
        if(localStorage.getItem('city')){
            const city = JSON.parse(localStorage.getItem('city'));
            city?.location ? setCenter(city.location) : setCenter([51.1729850769043,71.42532348632812])
        }
    }, [localStorage.getItem('city')])

    const showMyLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
            (position) => {
                setLocation([position.coords.latitude, position.coords.longitude]);
                mapRef.current.flyTo(
                    [position.coords.latitude, position.coords.longitude],
                    16, {
                        duration: 1
                    }
                );
        
            },
            (error) => {
                toast.error('Ошибка при получении местоположения:', error.message);
            }
            );
        } else {
            toast.error('Геолокация не поддерживается вашим браузером.');
        }
    };

    useEffect(() => {
        showMyLocation();
    }, [])

    const featchAddress = async (lat, lng) => {
        const res = await AppServices.address(lat, lng);

        if(res.address){
            setAddress({
                address: `${res.address.road} ${res.address.house_number ? res.address.house_number : ''}`,
                lat: lat,
                lng: lng
            })
            setAddressValue(`${res.address.road} ${res.address.house_number ? res.address.house_number : ''}`)
        }
    }
    
    useEffect(() => {
        if(location){
            featchAddress(location[0], location[1]);
        }
    }, [location])

    function LocationMarker() {
        const map = useMapEvents({
            click(e) {
                setLocation([e.latlng.lat, e.latlng.lng]);
            },
        })

        return (location === null || !location) ? null : (
          <Marker position={location}> </Marker>
        )
    }

  return (
    center &&
    <div className='position-relative'>
        <div onClick={showMyLocation} className='address-pin'>
            <i className='icon-location'></i>
        </div>
        <MapContainer center={center} zoom={12} ref={mapRef}>
            <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" minZoom={5}
            />
            {deliveryZones?.map((zone, index) =>
                <Polygon pathOptions={{ color: zone.subtitle ? zone.subtitle : 'green' }} positions={zone.zones} key={index}>
                    <Tooltip sticky>{zone.title}</Tooltip>
                </Polygon>
            )}
            <LocationMarker />
        </MapContainer>
    </div>
  )
})

export default MapComponent