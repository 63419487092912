import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Context} from "./index";
import AppRouter from './navigation/AppRouter';
import AppServices from './services/general/appServices';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './i18n/i18n'
import './App.scss';
import Header from './components/complex/Header';
import Footer from './components/complex/Footer';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import UserServices from './services/general/userServices';
import ScrollToTop from './hooks/scrollToTop';
import AdminTopHeader from './components/admin/parts/AdminTopHeader';
import AdminRouter from './navigation/AdminRouter';

const App = observer(() => {
    const {app, user} = useContext(Context);
    const [loading, setLoading] = useState();
    const queryParams = new URLSearchParams(window.location.search);

    const checkAuth = async () => {
        if(user.token){
            const res = await UserServices.profile.index();
            if(res.statusCode === 200){
                if(localStorage.getItem('editMode')){
                    app.setEditMode(true);
                }
                user.setUser(res.content)
            }else{
                user.setUser({})
                user.removeToken();
                user.setTokenChange(Math.random().toString(16));
                user.setBasketChange(Math.random().toString(16));
            }
        }
    }

    const fetchdata = async () => {
        setLoading(true);
        const res = await AppServices.settings();

        if(res.statusCode === 200){
            let settings = res.content;
            app.setSettings(settings);
            let userEnabled = settings.find(it => it.type === "user-enabled");
            userEnabled && app.setUserCabinet(userEnabled.value);

            let bonusesEnabled = settings.find(it => it.type === "bonuses_enabled")?.value;
            app.setBonuses(bonusesEnabled);

            let minimumOrderAmount = settings.find(it => it.type === "minimum-order-amount")?.value;
            app.setMinimumOrderAmount(minimumOrderAmount);

            let registerByPhone = settings.find(it => it.type === "register-by-phone")?.value;
            registerByPhone && app.setRegisterByPhone(registerByPhone);

            let color = settings.find(it => it.type === "color-primary")?.value;
            let footerColor = settings.find(it => it.type === "footer-color")?.value;
            let headerColor = settings.find(it => it.type === "header-color")?.value;
            let menuColor = settings.find(it => it.type === "menu-color")?.value;
            let bodyColor = settings.find(it => it.type === "body-color")?.value;
            let cardColor = settings.find(it => it.type === "card-color")?.value;
            let productCardColor = settings.find(it => it.type === "product-card-color")?.value;
            let partnersColor = settings.find(it => it.type === "partners-color")?.value;
            let reviewsColor = settings.find(it => it.type === "reviews-color")?.value;
            let bonusColor = settings.find(it => it.type === "bonus-color")?.value;

            let head = settings.find(it => it.type === "head")?.value;
            let bottom = settings.find(it => it.type === "bottom")?.value;
            let header = settings.find(it => it.type === "header")?.value;

            color && document.documentElement.style.setProperty('--mainColor', color);
            footerColor && document.documentElement.style.setProperty('--footerColor', footerColor);
            headerColor && document.documentElement.style.setProperty('--headerColor', headerColor);
            menuColor && document.documentElement.style.setProperty('--menuColor', menuColor);
            bodyColor && document.documentElement.style.setProperty('--bodyColor', bodyColor);
            cardColor && document.documentElement.style.setProperty('--cardColor', cardColor);
            productCardColor && document.documentElement.style.setProperty('--productCardColor', productCardColor);
            partnersColor && document.documentElement.style.setProperty('--partnersColor', partnersColor);
            reviewsColor && document.documentElement.style.setProperty('--reviewsColor', reviewsColor);
            bonusColor && document.documentElement.style.setProperty('--bonusColor', bonusColor);
            
            if(head){
                head.forEach(element => {
                    const divFragment =  document.createRange().createContextualFragment(element)
                    document.head.append(divFragment);
                });
            }

            if(header){
                header.forEach(element => {
                    const divFragment =  document.createRange().createContextualFragment(element)
                    document.body.append(divFragment);
                });
            }

            if(bottom){
                bottom.forEach(element => {
                    const divFragment =  document.createRange().createContextualFragment(element)
                    document.body.prepend(divFragment);
                });
            }
        }

        const res2 = await AppServices.localizations();

        if(res2.statusCode === 200){
            let temp;
            if(res2.content.find(it => it.slug === localStorage.getItem('i18nextLng'))){
                temp = localStorage.getItem('i18nextLng');
            }else{
                temp = res2.content[0].slug
                localStorage.setItem('i18nextLng', temp)
            }
            app.setLang(temp)
            let localizations = res2.content.find(it => it.slug === temp)?.description;

            let map2 = app.localizationsItems;
            for (let key in localizations) {
                map2[key] = localizations[key];
            }
            app.setLocalizationsItems(map2)

            app.setLanguages(res2.content)
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchdata();
    }, [app.lang])

    const tokenCheck = async(tokenParam) => {
        localStorage.setItem('token', tokenParam);

        const res = await UserServices.profile.index();

        if(res.statusCode === 200){
            app.setEditMode(true);
            localStorage.setItem('editMode', 'Режим редактирования')
            user.setToken(tokenParam);
            user.setUser(res.content)
            user.setTokenChange(Math.random().toString(16));
        }else{
            toast.error(res.message)
        }
    }

    useEffect(() => {
        const tokenParam = queryParams.get('token');

        if(tokenParam){
            tokenCheck(tokenParam);
        }else{
            checkAuth();
        }
    }, [])

  return (
    <>{loading ?
        <div className="fullpage-loader">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        :
        <BrowserRouter>
            <ScrollToTop />
            {(app.editMode && user.user?.roles?.find(it => it.name === 'admin')) ?
                <>
                    <AdminTopHeader/>
                    <div className='mob-site-header'>
                        <h2>{app.localizationsItems?.app_view_text}</h2>
                    </div>
                    <div className='main-site-wrapper'>
                        <Header/>
                        <AdminRouter/>
                        <Footer/>
                    </div>
                </>
                :
                <>
                    {user.user?.roles?.find(it => it.name === 'admin') && <AdminTopHeader/>}
                    <Header/>
                    <AppRouter/>
                    <Footer/>
                </>
                
            }
            <ToastContainer
                position="top-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </BrowserRouter>
    }</>
  )
});

export default App
