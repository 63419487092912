import React from 'react'
import img1 from "../../../images/inner-page/404.png"
import { NavLink } from 'react-router-dom'

const ErrorContainer = ({localizationsItems}) => {
  return (
	<section className="section-404 section-lg-space">
		<div className="container-fluid-lg">
			<div className="row">
				<div className="col-12">
					<div className="image-404">
						<img src={img1} className="img-fluid" alt="" loading='lazy'/>
					</div>
				</div>

				<div className="col-12">
					<div className="contain-404">
						<h3 className="text-content">{localizationsItems?.error_content}</h3>
						<NavLink className="btn btn-md text-white theme-bg-color mt-4 mx-auto" to={'/'}>{localizationsItems?.back_to_main}</NavLink>
					</div>
				</div>
			</div>
		</div>
	</section>
  )
}

export default ErrorContainer