import React from 'react'
import img from "../../images/placeholder.png"

const ReviewBox = ({card}) => {
  return (
    <div className="reviewer-box">
        <i className="fa-solid fa-quote-right"></i>
        <h3>{card.subtitle}</h3>

        <p dangerouslySetInnerHTML={{ __html: card.description }}/>

        <div className="reviewer-profile">
            <div className="reviewer-image">
                <img src={card.image ? card.image : img} alt=""
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img
                    }}
                    loading='lazy'
                />
            </div>

            <div className="reviewer-name">
                <h4>{card.title}</h4>
            </div>
        </div>
    </div>
  )
}

export default ReviewBox