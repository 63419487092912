import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { Context } from '../../..';
import { observer } from 'mobx-react-lite';
import img from "../../../images/placeholder.png"
import CategoryEditModal from '../modals/CategoryEditModal';
import DeleteModal from '../modals/DeleteModal';
import AdminCatalogServices from '../../../services/admin/AdminCatalogServices';

const AdminCategoryItem = observer(({
    card,
    categories,
    fetchCategories
}) => {
    const {app} = useContext(Context);

    const [showEdit, setSHowEdit] = useState();
    const [deleteModal, setDeleteModal] = useState();

    const [isPublished, setIsPublished] = useState(card.is_published);

    const changePublished = () => {

    }

  return (
    <>
    <div className={isPublished ? 'admin-banner-hover' : 'admin-banner-hover not-published'}>
        {!isPublished && <div className='product-card-unpublished'>
            <i className='icon-visibility_off'></i>
        </div>}
        <NavLink to={`/${app.lang}/catalog/${card.slug}`} className="category-box wow fadeInUp">
            <div>
                <img src={'https://shop-003-003.panama.kz'+card.image} alt={card.title}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img
                    }}
                    loading='lazy'
                />
                <h5>{card.title}</h5>
            </div>
        </NavLink>
        <div className='admin-banner-item-content admin-content-mini admin-category-grid'>
            <div className='admin-banner-btn change' onClick={changePublished}>
                <i className={isPublished ? 'icon-visibility' : 'icon-visibility_off'}></i>
            </div>
            <div className='admin-banner-btn change'>
                    <NavLink to={`/${app.lang}/catalog/${card.slug}`}>
                        <i className='fa fa-share'></i>
                    </NavLink>
                </div>
            <div className='admin-banner-btn edit' onClick={() => setSHowEdit(true)}>
                <i className='icon-pen'></i>
            </div>
            <div className='admin-banner-btn delete' onClick={() => setDeleteModal(true)}>
                <i className='icon-delete'></i>
            </div>
        </div>
    </div>
        {deleteModal &&
            <DeleteModal
                show={deleteModal}
                setShow={setDeleteModal}
                data={card.id}
                method={AdminCatalogServices.categories.delete}
                successCallback={fetchCategories}
            />
        }
        {showEdit && 
            <CategoryEditModal
                show={showEdit}
                setShow={setSHowEdit}
                categories={categories}
                card={card}
                successCallback={fetchCategories}
            />
        }
    </>
  )
})

export default AdminCategoryItem