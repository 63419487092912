import http from "./http-common"
import {deleteData, getData, postData} from "../handlers";

export default class AdminArticlesServices {
    static index = (queryString) => {
        return getData(http, `/articles${queryString ? `?${queryString}` : ""}`);
    }

    static view = (id, queryString) => {
        return getData(http, `/articles/${id}${queryString ? `${queryString}` : ""}`);
    }

    static store = (data) => {
        return postData(http, `/articles/store`, data);
    }

    static update = (id, data) => {
        return postData(http, `/articles/${id}`, data);
    }

    static delete = (id) => {
        return deleteData(http, `/articles/${id}`);
    }

    static localizations = {
        index: (id) => {
            return getData(http, `/articles/${id}/localizations`);
        },  
        view: (parentId, id) => {
            return getData(http, `/articles/${parentId}/localizations/${id}`);
        },
        store: (id, data) => {
            return postData(http, `/articles/${id}/localizations/store`, data);
        },
        update: (parentId, id, data) => {
            return postData(http, `/articles/${parentId}/localizations/${id}`, data);
        },
        delete: (parentId, id) => {
            return deleteData(http, `/articles/${parentId}/localizations/${id}`);
        },
    }
}