import { observer } from 'mobx-react-lite';
import React, { useContext} from 'react'
import { NavLink } from 'react-router-dom';
import { Context } from '../..';
import img from "../../images/placeholder.png"

const CatalogCategories = observer(({categories}) => {
    const {app} = useContext(Context);

  return (
    categories?.length >= 1 &&
    <div className="row">
        <div className="col-12">
                <div className="slider-9 mb-3">
                    {categories.map((card, index)=>
                        <div key={index}>
                            <NavLink to={`/${app.lang}/catalog/${card.slug}`} className="category-box wow fadeInUp">
                                <div>
                                    <img src={card.image} alt={card.title}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; 
                                            currentTarget.src = img
                                        }}
                                        loading='lazy'
                                    />
                                    <h5>{card.title}</h5>
                                </div>
                            </NavLink>
                        </div>
                    )}
                </div>
        </div>
    </div>
    
  )
})

export default CatalogCategories